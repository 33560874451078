import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DashboardTrustBeneficiaryProfileConnectionForm from './DashboardTrustBeneficiaryProfileConnectionForm';
import DashboardTrustBeneficiaryProfileConnectionsAttachForm from './DashboardTrustBeneficiaryProfileConnectionsAttachForm';
import DashboardTrustBeneficiaryProfileConnectionsRemoveForm from './DashboardTrustBeneficiaryProfileConnectionsRemoveForm';
import BasicTableBuilder from 'react/member/components/dashboard/misc/BasicTableBuilder';
import ConnectionStore from 'react/member/stores/ConnectionStore';
import Actions from 'react/shared/actions/actions';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

const useStyles = makeStyles(() => ({
  chip: {
    margin: 0,
  },
}));

export default function DashboardTrustBeneficiaryProfileConnections({
  canEdit,
  trustBeneficiary,
  expanded,
  toggleExpanded,
}) {
  const classes = useStyles();
  const [addingConnection, setAddingConnection] = useState(false);
  const [attachingConnection, setAttachingConnection] = useState(false);
  const [editingConnection, setEditingConnection] = useState(null);
  const [deletingConnection, setDeletingConnection] = useState(null);
  const [connections, setConnections] = useState([]);
  const statementRecipientsEnabled = $tlf.flipperEnabledConnectionStatementRecipients;

  useEffect(() => {
    const trustBeneficiarySlug = trustBeneficiary.slug;
    const fetchedConnections = (connections) => setConnections(connections);
    const createdConnection = () => setAddingConnection(false);
    const updatedConnection = () => setEditingConnection(null);
    const attachedConnection = () => setAttachingConnection(false);
    const deletedConnection = () => setDeletingConnection(null);

    ConnectionStore.on(`connections.fetch.${trustBeneficiarySlug}`, fetchedConnections);
    ConnectionStore.on(`connections.updated.${trustBeneficiarySlug}`, fetchedConnections);
    ConnectionStore.on(`connections.create.${trustBeneficiarySlug}`, createdConnection);
    ConnectionStore.on(`connections.update.${trustBeneficiarySlug}`, updatedConnection);
    ConnectionStore.on(`connections.link.${trustBeneficiarySlug}`, attachedConnection);
    ConnectionStore.on(`connections.unlink.${trustBeneficiarySlug}`, deletedConnection);

    Actions.fetchConnections(trustBeneficiarySlug);
    return () => {
      ConnectionStore.off(`connections.fetch.${trustBeneficiarySlug}`, fetchedConnections);
      ConnectionStore.off(`connections.updated.${trustBeneficiarySlug}`, fetchedConnections);
      ConnectionStore.off(`connections.create.${trustBeneficiarySlug}`, createdConnection);
      ConnectionStore.off(`connections.update.${trustBeneficiarySlug}`, updatedConnection);
      ConnectionStore.off(`connections.link.${trustBeneficiarySlug}`, attachedConnection);
      ConnectionStore.off(`connections.unlink.${trustBeneficiarySlug}`, deletedConnection);
    };
  }, [trustBeneficiary.slug]);

  const renderAddConnectionModal = () => {
    if (!addingConnection && !editingConnection) return;

    const modalProps = {
      trustBeneficiarySlug: trustBeneficiary.slug,
      onClose: () => setAddingConnection(false, setEditingConnection(null)),
      ...(editingConnection ? { connection: editingConnection } : {}),
      statementMailingEnabled: trustBeneficiary.mailStatement,
      isTaxDocRecipient: trustBeneficiary.taxDocRecipientConnectionId === editingConnection?.id,
    };
    return <DashboardTrustBeneficiaryProfileConnectionForm {...modalProps} />;
  };

  const renderAttachConnectionModal = () => {
    if (!attachingConnection) return;

    return (
      <TrueLinkModal
        handleClose={() => setAttachingConnection(false)}
        scrollable={false}
        title={'Search for Connection'}
      >
        <DashboardTrustBeneficiaryProfileConnectionsAttachForm
          trustBeneficiary={trustBeneficiary}
        />
      </TrueLinkModal>
    );
  };

  const renderDeleteConnectionModal = () => {
    if (!deletingConnection) return;

    return (
      <TrueLinkModal handleClose={() => setDeletingConnection(null)} title={'Remove Connection'}>
        <DashboardTrustBeneficiaryProfileConnectionsRemoveForm
          connection={deletingConnection}
          trustBeneficiary={trustBeneficiary}
        />
      </TrueLinkModal>
    );
  };

  const renderHeader = () => (
    <ToggleSectionHeader
      itemCount={connections.length}
      title={statementRecipientsEnabled ? 'Connections & Statement Recipients' : 'Connections'}
      {...{ expanded, toggleExpanded }}
    >
      {canEdit ? (
        <div className="action-item">
          <button
            className="edit-link btn btn-link"
            onClick={() => setAddingConnection(true)}
            type="button"
          >
            <i className="fa fa-plus-circle" />
            <span className="padding-5l">Add Connection</span>
          </button>
        </div>
      ) : null}
      {canEdit ? (
        <div className="action-item">
          <button
            className="edit-link btn btn-link"
            onClick={() => setAttachingConnection(true)}
            type="button"
          >
            <i className="fa fa-link" />
            <span className="padding-5l">Attach Connection</span>
          </button>
        </div>
      ) : null}
    </ToggleSectionHeader>
  );

  const columnHeadersList = () => [
    'name',
    'relationship',
    'companyName',
    'email',
    'phone',
    'address',
    'actionItems',
  ];

  const renderContentTable = () => {
    const { truncateWithEllipses, formatPhoneNumber } = tlFieldTransformers;
    const tableFieldConfig = {
      name: {
        title: 'Name',
        value: (connection) => (
          <>
            <p>{[connection.person.firstName, connection.person.lastName].join(' ')}</p>
            {connection.connectionRelationship.isStatementRecipient && (
              <TrueLinkChip
                className={classes.chip}
                color="blue"
                label="Statement Recipient"
                size="small"
              />
            )}
            {connection.id === trustBeneficiary.taxDocRecipientConnectionId && (
              <Box sx={{ marginTop: 1 }}>
                <TrueLinkChip
                  className={classes.chip}
                  color="primary"
                  label="Tax Document Recipient"
                  size="small"
                />
              </Box>
            )}
          </>
        ),
      },
      relationship: {
        title: 'Relationship',
        value: (connection) => (
          <p>
            {[
              connection.connectionRelationship.professional,
              connection.connectionRelationship.social,
            ]
              .filter(Boolean)
              .join(', ')}
          </p>
        ),
      },
      email: {
        title: 'Email',
        thAttributes: { style: { width: '180px' } },
        value: (connection) => (
          <p>{connection.email ? truncateWithEllipses(connection.email, 25) : ''}</p>
        ),
      },
      phone: {
        title: 'Phone',
        thAttributes: { style: { width: '110px' } },
        value: (connection) => <p>{formatPhoneNumber(connection.person.mobile)}</p>,
      },
      companyName: {
        title: 'Company',
        thAttributes: { style: { width: '110px' } },
        value: (connection) => <p>{connection.companyName}</p>,
      },
      address: {
        title: 'Address',
        value: (connection) => {
          const street2 = <div>{connection.address.street2}</div>;
          const city = connection.address.city ? `${connection.address.city},` : '';
          return (
            <div>
              <div>{connection.address.street1}</div>
              {connection.address.street2 && street2}
              <div>
                {city} {connection.address.state} {connection.address.zip}
              </div>
            </div>
          );
        },
      },
      actionItems: {
        title: '',
        thAttributes: { style: { width: '60px' } },
        value: (connection) => {
          if (!canEdit) return null;
          return (
            <div className="action-items">
              <button
                className="action-item btn btn-link edit-connection"
                onClick={() => setEditingConnection(connection)}
                type="button"
              >
                <i className="fa fa-edit" />
              </button>
              <button
                className="action-item btn btn-link danger delete-connection"
                onClick={() => setDeletingConnection(connection)}
                type="button"
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    };

    return (
      <div className="content-table">
        <BasicTableBuilder
          columnList={columnHeadersList()}
          itemTypeKey={'connections'}
          tableFieldConfig={tableFieldConfig}
          tableItems={connections}
        />
      </div>
    );
  };

  const renderContentNoItemsMessage = () => {
    if (connections.length > 0) return null;
    return (
      <div className="default-content-message">
        <p>No connections attached to this beneficiary.</p>
      </div>
    );
  };

  const renderContent = () => {
    if (!expanded) return;
    return (
      <div className="toggle-section-content full-width">
        {renderContentTable()}
        {renderContentNoItemsMessage()}
      </div>
    );
  };

  return (
    <div className="toggle-section connections">
      {renderAddConnectionModal()}
      {renderAttachConnectionModal()}
      {renderDeleteConnectionModal()}
      {renderHeader()}
      {renderContent()}
    </div>
  );
}

DashboardTrustBeneficiaryProfileConnections.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    mailStatement: PropTypes.bool.isRequired,
    taxDocRecipientConnectionId: PropTypes.number,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};
