import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { REQUEST_DISBURSEMENT_OPEN } from './Constants';
import DisbursementApprovalModal from './DisbursementApprovalModal';
import RequestDisbursementButton from './RequestDisbursementButton';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardBudgetItemForm from 'react/member/components/dashboard/budget_items/DashboardBudgetItemForm';
import DashboardPaginatedDisbursementList from 'react/member/components/dashboard/disbursements/DashboardPaginatedDisbursementList';
import DisbursementDenialModal from 'react/member/components/dashboard/disbursements/DisbursementDenialModal';
import ApprovedDisbursementsTable from 'react/member/components/dashboard/disbursements/filter_table/ApprovedDisbursementsTable';
import PendingDisbursementsTable from 'react/member/components/dashboard/disbursements/filter_table/PendingDisbursementsTable';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardBudgetItemStore from 'react/member/stores/DashboardBudgetItemStore';
import DashboardDisbursementStore from 'react/member/stores/DashboardDisbursementStore';
import DashboardDraftDisbursementStore from 'react/member/stores/DashboardDraftDisbursementStore';
import DashboardOrganizationStore from 'react/member/stores/DashboardOrganizationStore';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import DashboardPositionStore from 'react/member/stores/DashboardPositionStore';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import EffectiveBalancesTooltip from 'react/shared/components/tooltips/EffectiveBalancesTooltip';
import PALETTE from 'react/shared/theme/palette';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function DisbursementTabsWrapper({
  disbursementsFor,
  organizationSlug,
  trustSlug,
  portfolioId,
  initialClient,
  displayedPaymentMethods,
  threeMonthInfo: propsThreeMonthInfo,
  showCashBalance,
  editExternalCheckDisposition,
  renderOnHoldDisbursements,
  showSendDisbursementBackToPending,
  userRoles,
}) {
  const [organization, _setOrganization] = useState({});

  // In the `callAfterCreateOrUpdate` event listener, we need the most up-to-date organization that is loaded.
  // The version in state is not updated in the event listener.
  // We can use `useRef` for this: https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state#answer-55265764
  const orgRef = useRef(organization);
  const setOrganization = (org) => {
    orgRef.current = org;
    _setOrganization(org);
  };

  const [showDisbursementForm, setShowDisbursementForm] = useState(
    window.sessionStorage.getItem(REQUEST_DISBURSEMENT_OPEN) === 'true',
  );
  useEffect(() => {
    window.sessionStorage.removeItem(REQUEST_DISBURSEMENT_OPEN);
  }, []);
  const [newDisbursement, setNewDisbursement] = useState(null);
  const [resumedDraftDisbursementId, setResumedDraftDisbursementId] = useState(null);
  const [showDisbursementNotificationBubble, setShowDisbursementNotificationBubble] =
    useState(false);
  const [
    showRecurringDisbursementNotificationBubble,
    setShowRecurringDisbursementNotificationBubble,
  ] = useState(false);
  const [showOnHoldDisbursementNotificationBubble, setShowOnHoldDisbursementNotificationBubble] =
    useState(false);
  const [showDraftDisbursementNotificationBubble, setShowDraftDisbursementNotificationBubble] =
    useState(false);
  const [positions, setPositions] = useState([]);
  const [threeMonthInfo, setThreeMonthInfo] = useState(propsThreeMonthInfo);
  const [payees, setPayees] = useState([]);
  const [payeesLoaded, setPayeesLoaded] = useState(false);
  const [budgetItemType, setBudgetItemType] = useState('one_time');

  const currentClientSlug = initialClient?.slug || null;

  const getDisbursementNotificationRoute = () => {
    if (currentClientSlug) {
      return RailsRoutes.dashboard_client_disbursements_notification_icon_path(initialClient.slug);
    }
    if (trustSlug) {
      return RailsRoutes.dashboard_trust_disbursements_notification_icon_path(trustSlug);
    }
    if (portfolioId) {
      return RailsRoutes.dashboard_portfolio_disbursements_notification_icon_path(portfolioId);
    }
    return RailsRoutes.dashboard_organization_disbursements_notification_icon_path(
      organizationSlug,
    );
  };

  const getDisbursementNotification = () =>
    $.ajax({
      url: getDisbursementNotificationRoute(),
      type: 'GET',
      dataType: 'JSON',
    }).done((response) => setShowDisbursementNotificationBubble(response));

  const getOnHoldDisbursementNotificationRoute = () => {
    if (currentClientSlug) {
      return RailsRoutes.dashboard_client_on_hold_disbursements_notification_icon_path(
        initialClient.slug,
      );
    }
    if (trustSlug) {
      return RailsRoutes.dashboard_trust_on_hold_disbursements_notification_icon_path(trustSlug);
    }
    if (portfolioId) {
      return RailsRoutes.dashboard_portfolio_on_hold_disbursements_notification_icon_path(
        portfolioId,
      );
    }
    return RailsRoutes.dashboard_organization_on_hold_disbursements_notification_icon_path(
      organizationSlug,
    );
  };

  const getOnHoldDisbursementNotification = () =>
    $.ajax({
      url: getOnHoldDisbursementNotificationRoute(),
      type: 'GET',
      dataType: 'JSON',
    }).done((response) => setShowOnHoldDisbursementNotificationBubble(response));

  const getDraftDisbursementNotificationRoute = () => {
    if (currentClientSlug) {
      return RailsRoutes.dashboard_client_draft_disbursements_notification_icon_path(
        initialClient.slug,
      );
    }
    if (trustSlug) {
      return RailsRoutes.dashboard_trust_draft_disbursements_notification_icon_path(trustSlug);
    }
    if (portfolioId) {
      return RailsRoutes.dashboard_portfolio_draft_disbursements_notification_icon_path(
        portfolioId,
      );
    }
    return RailsRoutes.dashboard_organization_draft_disbursements_notification_icon_path(
      organizationSlug,
    );
  };

  const getDraftDisbursementNotification = () =>
    $.ajax({
      url: getDraftDisbursementNotificationRoute(),
      type: 'GET',
      dataType: 'JSON',
    }).done((response) => setShowDraftDisbursementNotificationBubble(response));

  const getRecurringDisbursementNotificationRoute = () => {
    if (currentClientSlug) {
      return RailsRoutes.dashboard_client_recurring_disbursements_notification_icon_path(
        initialClient.slug,
      );
    }
    if (trustSlug) {
      return RailsRoutes.dashboard_trust_recurring_disbursements_notification_icon_path(trustSlug);
    }
    if (portfolioId) {
      return RailsRoutes.dashboard_portfolio_recurring_disbursements_notification_icon_path(
        portfolioId,
      );
    }
    return RailsRoutes.dashboard_organization_recurring_disbursements_notification_icon_path(
      organizationSlug,
    );
  };

  const getRecurringDisbursementNotification = () =>
    $.ajax({
      url: getRecurringDisbursementNotificationRoute(),
      type: 'GET',
      dataType: 'JSON',
    }).done((response) => setShowRecurringDisbursementNotificationBubble(response));

  const checkDisbursementNotifications = () => {
    getDisbursementNotification();
    if (renderOnHoldDisbursements) {
      getOnHoldDisbursementNotification();
    }
    getDraftDisbursementNotification();
    getRecurringDisbursementNotification();
  };

  const onChangeOrganization = () => {
    setOrganization(DashboardOrganizationStore.get(organizationSlug));
  };

  const isApprovalRequired = () => orgRef.current.disbursement_approval_required;

  const toggleDisbursementForm = (ev) => {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }

    setShowDisbursementForm((prevShowDisbursementForm) => !prevShowDisbursementForm);
    if (showDisbursementForm === false) {
      window.sessionStorage.setItem('RequestDisbursementsRouteTracking', 'DisbursementsTab');
    }
    setBudgetItemType('one_time');

    setResumedDraftDisbursementId(null);
  };

  const afterCreateActions = useCallback(
    (optionalDisbursement) => {
      window.location.hash = 'client-disbursements-tab';
      const disbursement = optionalDisbursement || newDisbursement;
      let afterCreateTab;
      if (disbursement.is_recurring) {
        afterCreateTab = 'recurring-disbursements'; // recurring
      } else if (disbursement.status === 'On Hold') {
        afterCreateTab = renderOnHoldDisbursements
          ? 'draft-disbursements'
          : 'one-time-disbursements';
      } else if (disbursement.status === 'Draft') {
        afterCreateTab = 'draft-disbursements';
      } else {
        afterCreateTab = 'one-time-disbursements'; // one-time
      }

      setNewDisbursement(null);
      window.location.hash = afterCreateTab;
      toggleDisbursementForm(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newDisbursement, renderOnHoldDisbursements],
  );

  const callAfterCreateOrUpdate = (disbursement, eventOpts) => {
    const isDraft = disbursement.status === 'Draft';
    // If the org requires approval, we show a modal after creation by setting newDisbursement
    // afterCreateActions then runs once this modal closes
    if (!isDraft && isApprovalRequired()) {
      return setNewDisbursement(disbursement);
    }

    let message;
    if (isDraft) {
      message =
        eventOpts && eventOpts.update
          ? 'Draft disbursement updated!'
          : 'Draft disbursement created!';
    } else if (disbursement.transfer_funded) {
      message =
        'Disbursement created, card funding has been initiated. Check card details for updated balance!';
    } else {
      message = 'New disbursement created!';
    }

    Truelink.flash('success', message);
    afterCreateActions(disbursement);
  };

  const onChangeBeneficiaryPayee = (changedPayees, bool) => {
    setPayees(changedPayees);
    setPayeesLoaded(bool);
  };

  const onAddBeneficiaryPayee = () => setPayees(DashboardPayeeStore.getPayees());

  const determineCashBalances = () => {
    if (!(positions.length > 0) || !initialClient) {
      return;
    }

    return {
      zeroDay: {
        effective_balance: initialClient.zero_day_effective_cash_balance,
      },
      oneMonth: {
        effective_balance: initialClient.one_month_effective_cash_balance,
      },
      threeMonths: {
        effective_balance: initialClient.effective_cash_balance,
      },
    };
  };

  const onChangePositions = () => {
    setPositions(DashboardPositionStore.getPositions());

    const cashBalances = determineCashBalances();

    let cashBalance;
    if (cashBalances) {
      cashBalance = cashBalances.threeMonths.effective_balance;
    }

    setThreeMonthInfo((prevThreeMonthInfo) => ({
      ...prevThreeMonthInfo,
      cash_balance: cashBalance,
    }));
  };

  useEffect(() => {
    DashboardOrganizationStore.on('organization.fetch', onChangeOrganization);
    DashboardBudgetItemStore.on('budgetItem.create', callAfterCreateOrUpdate);
    DashboardDraftDisbursementStore.on('draftDisbursement.update', callAfterCreateOrUpdate);
    DashboardPayeeStore.on('payees.clear', onChangeBeneficiaryPayee);
    DashboardPayeeStore.on('payees.fetch', onChangeBeneficiaryPayee);
    DashboardPayeeStore.on('payees.add', onAddBeneficiaryPayee);
    DashboardPositionStore.on('positions.fetchByClient', onChangePositions);
    DashboardDisbursementStore.on('disbursement.update', checkDisbursementNotifications);
    RecurringDisbursementStore.on('recurringDisbursement.update', checkDisbursementNotifications);

    DashboardActions.fetchOrganization(organizationSlug);
    DashboardActions.fetchClientsByOrganization(organizationSlug);

    if (currentClientSlug !== null) {
      DashboardActions.fetchPayees(currentClientSlug);
      if (showCashBalance) {
        DashboardActions.fetchPositionsByClient(currentClientSlug);
      }
    }

    checkDisbursementNotifications();
    return function cleanup() {
      DashboardOrganizationStore.off('organization.fetch', onChangeOrganization);
      DashboardBudgetItemStore.off('budgetItem.create', callAfterCreateOrUpdate);
      DashboardDraftDisbursementStore.off('draftDisbursement.update', callAfterCreateOrUpdate);
      DashboardPayeeStore.off('payees.clear', onChangeBeneficiaryPayee);
      DashboardPayeeStore.off('payees.fetch', onChangeBeneficiaryPayee);
      DashboardPayeeStore.off('payees.add', onAddBeneficiaryPayee);
      DashboardPositionStore.off('positions.fetchByClient', onChangePositions);
      DashboardDisbursementStore.off('disbursement.update', checkDisbursementNotifications);
      RecurringDisbursementStore.off(
        'recurringDisbursement.update',
        checkDisbursementNotifications,
      );
      window.sessionStorage.removeItem('RequestDisbursementsRouteTracking');
    };
    //This is an old component that was written with a ton of dependencies and disentangling this is risky
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdditionalApprovalRequired = () =>
    organization?.disbursement_additional_approval_required;

  const actionPath = (disbursementType, isDraft = false) => {
    if (disbursementType === 'recurring') {
      return RailsRoutes.dashboard_recurring_disbursements_path({ format: 'json' });
    } else if (disbursementType === 'one_time' && isDraft) {
      return RailsRoutes.dashboard_draft_disbursements_path({ format: 'json' });
    }

    return RailsRoutes.dashboard_disbursements_path({ format: 'json' });
  };

  const onResumeDraft = (draftDisbursmentId) => {
    setShowDisbursementForm(true);
    setResumedDraftDisbursementId(draftDisbursmentId);
    setBudgetItemType('one_time');
  };

  const getTabList = () => {
    const tabList = [
      {
        id: 'one-time-disbursements',
        title: 'One-Time',
        className: 'btn btn-secondary',
        hideHref: true,
        generic: showDisbursementNotificationBubble,
      },
      {
        id: 'recurring-disbursements',
        title: 'Recurring',
        className: 'btn btn-secondary',
        hideHref: true,
        generic: showRecurringDisbursementNotificationBubble,
      },
    ];

    if (renderOnHoldDisbursements) {
      tabList.push({
        id: 'on-hold-disbursements',
        title: 'On Hold',
        className: 'btn btn-secondary',
        hideHref: true,
        generic: showOnHoldDisbursementNotificationBubble,
      });
    }

    tabList.push({
      id: 'draft-disbursements',
      title: 'Draft',
      className: 'btn btn-secondary',
      hideHref: true,
      generic: showDraftDisbursementNotificationBubble,
    });
    return tabList;
  };

  const index = () => {
    const client = initialClient;
    const clientSlug = client ? client.slug : undefined;

    const commonListProps = {
      clientSlug,
      organizationSlug,
      portfolioId,
      trustSlug,
      payees,
    };

    const balanceInfo = determineCashBalances();

    let cashBalance;
    if (balanceInfo) {
      cashBalance = balanceInfo.threeMonths.effective_balance;
    }

    return (
      <div>
        <div style={{ marginTop: '25px' }}>
          {cashBalance !== undefined && showCashBalance && (
            <div style={{ borderBottom: '0.05em solid lightgrey', marginBottom: '15px' }}>
              <EffectiveBalancesTooltip
                balanceInfo={balanceInfo}
                organizationSlug={organizationSlug}
                showCashBalance
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    display: 'inline-block',
                    borderBottom: `1px dashed ${PALETTE.grey3}`,
                    margin: `0px 20px 15px 0px`,
                  }}
                >
                  Effective Cash Balance:
                </Typography>
                <div style={{ fontSize: 18, display: 'inline-block' }}>
                  {tlFieldTransformers.formatMoney(cashBalance)}
                </div>
              </EffectiveBalancesTooltip>
            </div>
          )}

          <RequestDisbursementButton
            cashBalance={showCashBalance ? cashBalance : undefined}
            disbursementsFor={disbursementsFor}
            initialClient={initialClient}
            toggleDisbursementForm={toggleDisbursementForm}
          />
          <DisbursementDenialModal />

          <TabNavigation classOptions={'btn-group'} initialTabIndex={0} tabs={getTabList()}>
            <TabContent id="one-time-disbursements-table">
              {isApprovalRequired() && (
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="proposed_beneficiary_requested"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="proposed_beneficiary_requested"
                  userRoles={userRoles}
                />
              )}
              {isApprovalRequired() && (
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="proposed"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="proposed"
                  userRoles={userRoles}
                />
              )}
              {!$tlf.flipperNewPendingDisbursementTable && isAdditionalApprovalRequired() && (
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="additional_approval"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  showSendDisbursementBackToPending={showSendDisbursementBackToPending}
                  statusFilter="additional_approval"
                  userRoles={userRoles}
                />
              )}
              {$tlf.flipperNewPendingDisbursementTable &&
                isAdditionalApprovalRequired() &&
                portfolioId && (
                  <DashboardPaginatedDisbursementList
                    //Display old pending table for portfolio
                    {...commonListProps}
                    editExternalCheckDisposition={editExternalCheckDisposition}
                    key="additional_approval"
                    renderOnHoldDisbursements={renderOnHoldDisbursements}
                    showSendDisbursementBackToPending={showSendDisbursementBackToPending}
                    statusFilter="additional_approval"
                    userRoles={userRoles}
                  />
                )}
              {$tlf.flipperNewPendingDisbursementTable &&
                isAdditionalApprovalRequired() &&
                !portfolioId && (
                  <PendingDisbursementsTable
                    clientSlug={clientSlug}
                    editExternalCheckDisposition={editExternalCheckDisposition}
                    filter="additional approval"
                    organizationSlug={organizationSlug}
                    renderOnHoldDisbursements={renderOnHoldDisbursements}
                    showSendDisbursementBackToPending={showSendDisbursementBackToPending}
                    userRoles={userRoles}
                  />
                )}
              {!$tlf.flipperNewPendingDisbursementTable && isApprovalRequired() && (
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="pending"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="pending"
                  userRoles={userRoles}
                />
              )}
              {$tlf.flipperNewPendingDisbursementTable && isApprovalRequired() && portfolioId && (
                <DashboardPaginatedDisbursementList
                  //Display old pending table for portfolio
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="pending"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="pending"
                  userRoles={userRoles}
                />
              )}
              {$tlf.flipperNewPendingDisbursementTable && isApprovalRequired() && !portfolioId && (
                <PendingDisbursementsTable
                  clientSlug={clientSlug}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  filter="pending"
                  organizationSlug={organizationSlug}
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  userRoles={userRoles}
                />
              )}
              {clientSlug && <ApprovedDisbursementsTable clientId={clientSlug} />}
              <DashboardPaginatedDisbursementList
                clientSlug={clientSlug}
                editExternalCheckDisposition={editExternalCheckDisposition}
                key="cancelled"
                organizationSlug={organizationSlug}
                portfolioId={portfolioId}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                statusFilter="cancelled"
                trustSlug={trustSlug}
                userRoles={userRoles}
              />
            </TabContent>
            <TabContent id="recurring-disbursements-table">
              {isApprovalRequired() && (
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  isRecurring
                  key="recurring_proposed"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="proposed"
                  userRoles={userRoles}
                />
              )}
              {isAdditionalApprovalRequired() && (
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  isRecurring
                  key="recurring_additional_approval"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="additional_approval"
                  userRoles={userRoles}
                />
              )}
              {isApprovalRequired() && (
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  isRecurring
                  key="recurring_pending"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="pending"
                  userRoles={userRoles}
                />
              )}
              <DashboardPaginatedDisbursementList
                clientSlug={clientSlug}
                editExternalCheckDisposition={editExternalCheckDisposition}
                isRecurring
                key="recurring_approved"
                organizationSlug={organizationSlug}
                portfolioId={portfolioId}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                statusFilter="approved"
                trustSlug={trustSlug}
                userRoles={userRoles}
              />
              <DashboardPaginatedDisbursementList
                clientSlug={clientSlug}
                editExternalCheckDisposition={editExternalCheckDisposition}
                isRecurring
                key="recurring_cancelled"
                organizationSlug={organizationSlug}
                portfolioId={portfolioId}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                statusFilter="cancelled"
                trustSlug={trustSlug}
                userRoles={userRoles}
              />
            </TabContent>
            {renderOnHoldDisbursements && (
              <TabContent id="on-hold-disbursements-table">
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="pending_on_hold"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="pending_on_hold"
                  userRoles={userRoles}
                />
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="additional_approval_on_hold"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="additional_approval_on_hold"
                  userRoles={userRoles}
                />
                <DashboardPaginatedDisbursementList
                  {...commonListProps}
                  editExternalCheckDisposition={editExternalCheckDisposition}
                  key="proposed_on_hold"
                  renderOnHoldDisbursements={renderOnHoldDisbursements}
                  statusFilter="proposed_on_hold"
                  userRoles={userRoles}
                />
              </TabContent>
            )}
            <TabContent id="draft-disbursements-table">
              <DashboardPaginatedDisbursementList
                {...commonListProps}
                editExternalCheckDisposition={editExternalCheckDisposition}
                key="draft"
                onResumeDraft={onResumeDraft}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                statusFilter="draft"
                userRoles={userRoles}
              />
            </TabContent>
          </TabNavigation>
        </div>
      </div>
    );
  };

  return (
    <div>
      {showDisbursementForm ? (
        <DashboardBudgetItemForm
          actionPath={actionPath}
          budgetItemType={budgetItemType}
          currentClientSlug={currentClientSlug}
          displayedPaymentMethods={displayedPaymentMethods}
          editExternalCheckDisposition={editExternalCheckDisposition}
          initialClient={initialClient}
          organizationSlug={organizationSlug}
          payees={payees}
          payeesLoaded={payeesLoaded}
          resumedDraftDisbursementId={resumedDraftDisbursementId}
          setBudgetItemType={setBudgetItemType}
          threeMonthInfo={threeMonthInfo}
          toggleBudgetItemForm={toggleDisbursementForm}
          userRoles={userRoles}
        />
      ) : (
        index()
      )}
      {newDisbursement && (
        <DisbursementApprovalModal
          disbursement={newDisbursement}
          onClose={() => afterCreateActions()}
        />
      )}
    </div>
  );
}

DisbursementTabsWrapper.propTypes = {
  disbursementsFor: PropTypes.oneOf(['Trust Beneficiary', 'Trust', 'Portfolio', 'Organization'])
    .isRequired,
  organizationSlug: PropTypes.string.isRequired,
  trustSlug: PropTypes.string,
  portfolioId: PropTypes.number,
  initialClient: PropTypes.object,
  displayedPaymentMethods: PropTypes.array,
  threeMonthInfo: PropTypes.object,
  showCashBalance: PropTypes.bool,
  editExternalCheckDisposition: PropTypes.bool,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
};

DisbursementTabsWrapper.defaultProps = {
  renderOnHoldDisbursements: false,
};
