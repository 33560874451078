/* eslint-disable no-case-declarations */
import axios from 'axios';
import $ from 'jquery';
// application setup
let account_id = '';

// get account_id (i.e. the slug) from the url to add to the headers
if (
  document.URL.includes('member') ||
  document.URL.includes('cardholder') ||
  document.URL.includes('request_additional_card') ||
  document.URL.includes('accounts') ||
  document.URL.includes('new-invited-family-funding')
) {
  if (window.user && window.user.account_slug) {
    account_id = window.user.account_slug;
  } else {
    account_id = document.URL.slice(Math.max(0, document.URL.lastIndexOf('/') + 1));
    account_id = account_id.split('#')[0].split('?')[0];
    account_id = account_id.replace(/\.html$/, '');
  }
}

(function () {
  $.ajaxSetup({
    beforeSend(jqXHR, _settings) {
      if (!jqXHR.crossDomain) {
        jqXHR.setRequestHeader('X-ACCOUNT-ID', account_id);
      }
    },
  });

  let genericErrorMessage = 'An error has occurred. ';
  if (window.support) {
    genericErrorMessage += 'For assistance, call Customer Support at ';
    genericErrorMessage += window.support.phone;
    genericErrorMessage += ' or email ';
    genericErrorMessage += window.support.email;
  }

  function handleSessionTimeout() {
    const signoutMessage = "You've been automatically logged out. Please sign back in to continue.";
    Truelink.flash('session-timeout', signoutMessage);
    window.setTimeout(() => {
      document.location.href = '/sign-in';
    }, 1000);
  }

  $(document).ajaxComplete((event, request) => {
    // display standard http error responses
    switch (request.status) {
      case 401:
        handleSessionTimeout();
        break;
      case 400:
      case 403:
        let unauthorizedMsg = request.responseJSON?.message || 'You are not authorized to peform this action.'
        Truelink.flash('error', unauthorizedMsg, true);
        break;
      case 406:
        // expected, form validation fail
        if (request.responseJSON && request.responseJSON.message) {
          Truelink.flash('error', request.responseJSON.message, true);
        }
        break;
      case 422:
        // also should be expected, this is real form validation failure
        const jsonMessage =
          request.responseJSON !== undefined ? request.responseJSON.message : undefined;
        let errorMessage = jsonMessage || genericErrorMessage;
        errorMessage = Array.isArray(errorMessage) ? errorMessage.join(', ') : errorMessage;
        Truelink.flash('error', errorMessage, true);
        break;
      case 404:
        Truelink.flash('error', genericErrorMessage, true);
        break;
      case 500:
        Truelink.flash('error', genericErrorMessage);
        break;
    }

    // display flash messages
    let msg = request.getResponseHeader('X-FlashError');
    if (msg) {
      Truelink.flash('error', msg, true, false);
      return;
    }
    msg = request.getResponseHeader('X-FlashNotice');
    if (msg) {
      Truelink.flash('notice', msg, true, true);
      return;
    }
    msg = request.getResponseHeader('X-FlashSuccess');
    if (msg) {
      Truelink.flash('success', msg, true, true);
      return;
    }
    msg = request.getResponseHeader('X-FlashNoticeNoFade');
    if (msg) {
      Truelink.flash('notice', msg, true, false);
      return;
    }
    msg = request.getResponseHeader('X-FlashSuccessNoFade');
    if (msg) {
      Truelink.flash('success', msg, true, false);
    }
  });

  // Axios Config:
  if (axios) {
    $(() => {
      axios.defaults.headers.common['X-ACCOUNT-ID'] = account_id;
    });

    axios.interceptors.response.use(
      (successResponse) => {
        let successMsg = successResponse.headers['x-flashsuccess'];
        if (successMsg) {
          // If a success message is found, display it
          Truelink.flash('success', successMsg);
        }
        return successResponse;
      },
      (error) => {
      switch (error.response && error.response.status) {
        case 401:
          handleSessionTimeout();
          break;
        case 400:
        case 422:
          Truelink.flashClear();
          let errorMessage;
          if (error.response.data.errors && Array.isArray(error.response.data.errors)) {
            // Handling for JSON:API error format
            const messages = error.response.data.errors.map((error) => {
              // Handle cases where we got an array of strings instead of objects
              if (typeof error == 'string') {
                return error;
              }

              // Otherwise assume this is JSON:API format
              return error.detail;
            })
            errorMessage = messages.join(', ');
          } else {
            errorMessage = error.response.data.message || genericErrorMessage;
            errorMessage = Array.isArray(errorMessage) ? errorMessage.join(', ') : errorMessage;
          }
          Truelink.flash('error', errorMessage);
          break;
        case 404:
        case 500:
          Truelink.flash('error', genericErrorMessage);
          break;
      }

      return Promise.reject(error);
    });
  }

  window.Truelink = {
    flash(type, message, preserveOld, fadeAway) {
      if (!message) {
        return;
      }
      let $flash = $('.flash:visible');
      if ($flash.length === 0) {
        $flash = $('#flash');
      }
      if ($flash.length === 0) {
        return;
      }
      if (!preserveOld) {
        $flash.children().remove();
      }
      const $d = $(`<div class="alert alert-${type}" data-dismiss="alert">`);
      const $closeBtn = $('<button class="close">').text('×');
      if (typeof message === 'object') {
        $d.append(message).append($closeBtn);
      } else {
        $d.text(message).append($closeBtn);
      }
      if (!Truelink.flashExists($flash, $d)) {
        $flash.append($d);
      }
      // remove old ones if too many
      while ($('#flash .alert').length > 4) {
        const old = $('#flash .alert').get(0);
        if (old) {
          old.remove();
        }
      }
      if (fadeAway) {
        window.setTimeout(() => {
          $d.fadeOut(() => {
            $d.remove();
          });
        }, 4000);
      }
    },
    flashExists($flash, $d) {
      $flash.children().each(function () {
        // eslint-disable-next-line no-invalid-this
        if ($(this).text() === $d.text()) {
          return true;
        }
      });
      return false;
    },
    flashClear(selector) {
      $('#flash').children(selector).remove();
      $('.flash:visible').children(selector).remove();
    },
    clearFormErrors() {
      $('label[class=error]').remove();
    },
    // Error placement for form elements
    formErrorPlacement(error, formElement) {
      let errorElement = error;
      const $formElement = $(formElement);
      if (typeof errorElement === 'string') {
        errorElement = `<label for='${$formElement.prop(
          'id',
        )}' class='error'>${errorElement}</label>`;
      }

      const $error = $(error);
      const $label = $(`label[for=${$formElement.prop('id')}]`);
      if ($label.length) {
        $label.append($error);
      } else {
        $formElement.after($error);
      }
    },
  };

  function init() {
    const railsEnv = $('body').attr('data-environment');
    if (railsEnv === 'test') {
      if (window.$) {
        $.fx.off = true;
      }
    }
  }

  $(document).ready(init);
})();
