import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import DashboardTrustBeneficiaryProfileAccountClosing from './DashboardTrustBeneficiaryProfileAccountClosing';
import DashboardTrustBeneficiaryProfileBereavement from './DashboardTrustBeneficiaryProfileBereavement';
import DashboardTrustBeneficiaryProfileDocuments from './DashboardTrustBeneficiaryProfileDocuments';
import DashboardTrustBeneficiaryProfileConnections from './connections/DashboardTrustBeneficiaryProfileConnections';
import DashboardTrustBeneficiaryProfileContactInfo from './contact_info/DashboardTrustBeneficiaryProfileContactInfo';
import DashboardTrustBeneficiaryProfileCoreInfo from './core_info/DashboardTrustBeneficiaryProfileCoreInfo';
import DashboardTrustBeneficiaryProfileAccountActivityNotes from './dashboard_notes/DashboardTrustBeneficiaryProfileAccountActivityNotes';
import DashboardTrustBeneficiaryProfileQuickAccessNote from './dashboard_notes/DashboardTrustBeneficiaryProfileQuickAccessNote';
import DashboardTrustBeneficiaryProfileGovernmentBenefits from './government_benefits/DashboardTrustBeneficiaryProfileGovernmentBenefits';
import DashboardTrustBeneficiaryProfileTrustRemainderBeneficiaries from './trust_remainder_beneficiaries/DashboardTrustBeneficiaryProfileTrustRemainderBeneficiaries';
import DashboardTrustBeneficiaryProfileTrustStatementRecipients from './trust_statement_recipients/DashboardTrustBeneficiaryProfileTrustStatementRecipients';
import DashboardActions from 'react/member/actions/dashboard_actions';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardTrustBeneficiaryStore from 'react/member/stores/beneficiary_dashboard/DashboardTrustBeneficiaryStore';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';

function DashboardTrustBeneficiaryProfile({ trustBeneficiarySlug, userRoles }) {
  const connectionStatementRecipientsEnabled = $tlf.flipperEnabledConnectionStatementRecipients;

  function expandedDefaults() {
    return {
      profileContactInfo: false,
      profileGovernmentBenefits: false,
      profileBereavement: false,
      profileConnections: false,
      profileTrustStatementRecipients: false,
      profileTrustRemainderBeneficiaries: false,
      profileQuickAccessNote: false,
      profileAccountActivityNotes: false,
    };
  }

  const [trustBeneficiary, setTrustBeneficiary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allExpanded, setAllExpanded] = useState(false);
  const [expandedSections, setExpandedSections] = useState(expandedDefaults());
  const queryClient = useQueryClient();

  useEffect(() => {
    const onFetchData = (trustBeneficiaryData) => {
      setTrustBeneficiary(trustBeneficiaryData);
      setIsLoading(false);
      queryClient.invalidateQueries(['trustBeneficiaryShow', trustBeneficiarySlug]);
    };
    DashboardTrustBeneficiaryStore.on(
      `trustBeneficiary.fetch.${trustBeneficiarySlug}`,
      onFetchData,
    );
    DashboardTrustBeneficiaryStore.on(
      `trustBeneficiary.update.${trustBeneficiarySlug}`,
      onFetchData,
    );

    DashboardActions.fetchTrustBeneficiaryData(trustBeneficiarySlug);

    return () => {
      DashboardTrustBeneficiaryStore.off(
        `trustBeneficiary.fetch.${trustBeneficiarySlug}`,
        onFetchData,
      );
      DashboardTrustBeneficiaryStore.off(
        `trustBeneficiary.update.${trustBeneficiarySlug}`,
        onFetchData,
      );
    };
  }, [trustBeneficiarySlug, queryClient]);

  function refreshTrustBeneficiaryData() {
    DashboardActions.fetchTrustBeneficiaryData(trustBeneficiarySlug, true);
  }

  function expandAll() {
    const newExpandedSections = { ...expandedSections };

    Object.keys(newExpandedSections).forEach((key) => {
      newExpandedSections[key] = true;
    });

    setAllExpanded(true);
    setExpandedSections(newExpandedSections);
  }

  function collapseAll() {
    setAllExpanded(false);
    setExpandedSections(expandedDefaults());
  }

  function toggleSection(name) {
    setExpandedSections((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  }

  function isClosing() {
    return ['Closing requested', 'Closing', 'Inactive'].includes(trustBeneficiary.status);
  }

  function renderUnrestrictedViewSections() {
    return [
      <DashboardTrustBeneficiaryProfileGovernmentBenefits
        canEdit={userRoles.managesSubaccounts}
        expanded={expandedSections.profileGovernmentBenefits}
        key="beneProfileGovtBenefitsSection"
        toggleExpanded={() => toggleSection('profileGovernmentBenefits')}
        trustBeneficiarySlug={trustBeneficiary.slug}
      />,
      <DashboardTrustBeneficiaryProfileConnections
        canEdit={userRoles.managesSubaccounts}
        expanded={expandedSections.profileConnections}
        key="beneProfileConnectionsSection"
        toggleExpanded={() => toggleSection('profileConnections')}
        trustBeneficiary={trustBeneficiary}
      />,
      !connectionStatementRecipientsEnabled && (
        <DashboardTrustBeneficiaryProfileTrustStatementRecipients
          canEdit={userRoles.managesSubaccounts}
          expanded={expandedSections.profileTrustStatementRecipients}
          key="beneProfileTrustStatementRecipientsSection"
          toggleExpanded={() => toggleSection('profileTrustStatementRecipients')}
          trustBeneficiary={trustBeneficiary}
        />
      ),
      <DashboardTrustBeneficiaryProfileTrustRemainderBeneficiaries
        canEdit={userRoles.managesSubaccounts}
        expanded={expandedSections.profileTrustRemainderBeneficiaries}
        key="beneProfileTrustRemainderSection"
        toggleExpanded={() => toggleSection('profileTrustRemainderBeneficiaries')}
        trustBeneficiary={trustBeneficiary}
      />,
    ];
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const shouldHideNotes = isRestrictedViewUser();

  return (
    <div>
      <div className="toggle-section-table">
        <DashboardTrustBeneficiaryProfileCoreInfo
          allExpanded={allExpanded}
          canEdit={userRoles.managesSubaccounts}
          collapseAll={collapseAll}
          expandAll={expandAll}
          trustBeneficiary={trustBeneficiary}
        />
        <DashboardTrustBeneficiaryProfileContactInfo
          canEdit={userRoles.managesSubaccounts}
          expanded={expandedSections.profileContactInfo}
          toggleExpanded={() => toggleSection('profileContactInfo')}
          trustBeneficiary={trustBeneficiary}
        />
        {!isRestrictedViewUser() && renderUnrestrictedViewSections()}
        {isClosing() && (
          <DashboardTrustBeneficiaryProfileBereavement
            canEdit={userRoles.managesSubaccounts}
            expanded={expandedSections.profileBereavement}
            toggleExpanded={() => toggleSection('profileBereavement')}
            trustBeneficiary={trustBeneficiary}
          />
        )}
      </div>
      {!shouldHideNotes && (
        <div className="toggle-section-table">
          <div>
            <DashboardTrustBeneficiaryProfileQuickAccessNote
              expanded={expandedSections.profileQuickAccessNote}
              toggleExpanded={() => toggleSection('profileQuickAccessNote')}
              trustBeneficiary={trustBeneficiary}
            />
            <DashboardTrustBeneficiaryProfileAccountActivityNotes
              expanded={expandedSections.profileAccountActivityNotes}
              toggleExpanded={() => toggleSection('profileAccountActivityNotes')}
              trustBeneficiary={trustBeneficiary}
            />
          </div>
        </div>
      )}
      {!shouldHideNotes && (
        <div className="toggle-section-table">
          <DashboardTrustBeneficiaryProfileDocuments
            trustBeneficiary={trustBeneficiary}
            userRoles={userRoles}
          />
        </div>
      )}
      {userRoles.managesSubaccounts && !isClosing() && !isRestrictedViewUser() && (
        <div className="toggle-section-table">
          <DashboardTrustBeneficiaryProfileAccountClosing
            onSuccess={refreshTrustBeneficiaryData}
            style="profile-tab"
          />
        </div>
      )}
    </div>
  );
}

DashboardTrustBeneficiaryProfile.propTypes = {
  trustBeneficiarySlug: PropTypes.string.isRequired,
  userRoles: userRoleShape.isRequired,
};

export default DashboardTrustBeneficiaryProfile;
