import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import TaxInfoModal from './TaxInfoModal';
import DashboardActions from 'react/member/actions/dashboard_actions';
import useTrustBeneficiaryShowQuery from 'react/member/components/dashboard/clients/hooks/useTrustBeneficiaryShowQuery';
import { trustBeneficiaryShape } from 'react/member/components/dashboard/trust_beneficiaries/profile/core_info/CoreInfoShape';
import SsnMaskedStringShow from 'react/shared/components/SsnMaskedStringShow';
import TlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function Content({ trustBeneficiary, canEdit }) {
  const beneFetch = useTrustBeneficiaryShowQuery({ clientSlug: trustBeneficiary.slug });

  if (beneFetch.isLoading) {
    return null;
  }

  const disabilityDesignation = beneFetch.data.disabilityDesignation?.name;
  const showOverdrawAllowed = beneFetch.data.organization.meta.ableToOverdrawDisbursements;
  const showInvestmentDirective = beneFetch.data.organization.meta.showInvestmentDirective;
  const showSoleTrustee = beneFetch.data.organization.meta.showSoleTrustee;
  const ssnOnFile = beneFetch.data.meta.ssnOnFile;
  const taxIdNumberType = beneFetch.data.taxIdNumberType;
  const ein = beneFetch.data.ein;

  const refreshBene = () => {
    beneFetch?.refetch();
    DashboardActions.fetchTrustBeneficiaryData(trustBeneficiary.slug, true);
  };

  const { toYesNo } = TlFieldTransformers;
  // TODO: unhide the disability designation field when the disabilityDesignation is editable
  return (
    <div className="toggle-section-content flex">
      <div className="info-column">
        <div className="info-item" data-testid="dob">
          <strong>DOB: </strong>
          <span className="padding-10l">
            <Moment format="MM-DD-YYYY">{trustBeneficiary.person.dob}</Moment>
          </span>
        </div>

        <div className="info-item" data-testid="age">
          <strong>Age: </strong>
          <span className="padding-10l">{trustBeneficiary.person.age}</span>
        </div>

        <div className="info-item" data-testid="ssn">
          {canEdit ? (
            <SsnMaskedStringShow
              canViewSsn
              onSuccess={refreshBene}
              ssnOnFile={ssnOnFile}
              taxIdNumberType={taxIdNumberType}
              trustBeneficiaryId={trustBeneficiary.id}
              trustBeneficiaryStyle
            />
          ) : (
            <div>
              <strong>SSN: </strong>
              <span className="padding-10l">XXX-XX-XXXX</span>
            </div>
          )}
        </div>

        {showOverdrawAllowed && (
          <div className="info-item" data-testid="overdraw">
            <strong>Overdraw Allowed: </strong>
            <span className="padding-10l">{toYesNo(trustBeneficiary.overdrawAllowed)}</span>
          </div>
        )}
      </div>
      <div className="info-column constrained-width">
        <div className="info-item" data-testid="welcome-letter">
          <strong>Welcome Letter Signed: </strong>
          <span className="padding-10l">{toYesNo(trustBeneficiary.welcomeLetterSignedAt)}</span>
        </div>
        <div className="info-item" data-testid="settlement">
          <strong>Settlement: </strong>
          <span className="padding-10l">{toYesNo(trustBeneficiary.settlement)}</span>
        </div>
        <div className="info-item" data-testid="living-situation">
          <strong>Living Situation: </strong>
          <span className="padding-10l">{trustBeneficiary.person.livingSituation}</span>
        </div>
        <div className="info-item" data-testid="disability-designation">
          <strong>Disability Designation: </strong>
          <span className="padding-10l">{disabilityDesignation}</span>
        </div>
        {showInvestmentDirective && (
          <div className="info-item" data-testid="investment-dir">
            <strong>Investment Directive: </strong>
            <span className="padding-10l">{toYesNo(trustBeneficiary.investmentDirective)}</span>
          </div>
        )}
      </div>
      <div className="info-column">
        <div className="info-item" data-testid="gender">
          <strong>Gender: </strong>
          <span className="padding-10l">{trustBeneficiary.person.gender}</span>
        </div>
        <div className="info-item" data-testid="disb-creation">
          <strong>Disbursement Creation Enabled: </strong>
          <span className="padding-10l">
            {toYesNo(!trustBeneficiary.disallowDisbursementRequests)}
          </span>
        </div>
        <div className="info-item" data-testid="marital-status">
          <strong>Marital Status: </strong>
          <span className="padding-10l">{trustBeneficiary.person.maritalStatus}</span>
        </div>

        {showSoleTrustee && (
          <div className="info-item" data-testid="sole-trustee">
            <strong>Sole Trustee: </strong>
            <span className="padding-10l">{toYesNo(trustBeneficiary.soleTrustee)}</span>
          </div>
        )}
        <div className="info-item" data-testid="tax-id-number">
          <TaxInfoModal
            ein={ein}
            onSuccess={refreshBene}
            ssnOnFile={ssnOnFile}
            taxIdNumberType={taxIdNumberType}
            trustBeneficiary={beneFetch.data}
          />
        </div>
      </div>
    </div>
  );
}

Content.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  trustBeneficiary: trustBeneficiaryShape.isRequired,
};
