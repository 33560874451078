import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { grey4, grey } from 'react/shared/theme/palette';

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${grey4}`,
    '&:hover': {
      background: grey,
    },
  },
  cell: {
    padding: '15px',
  },
  selectorColumn: {
    maxWidth: '0',
    textAlign: 'left',
  },
});

export default function TableRow({
  rowId,
  row,
  rowProps = () => ({}),
  prepareRow,
  rowSubComponent,
  selectable,
}) {
  const classes = useStyles();
  prepareRow(row);

  // getRowProps property is mutably added to the row by React-Table
  // eslint-disable-next-line react/prop-types
  const generatedRowProps = row.getRowProps(rowProps(row));
  return (
    <>
      <tr
        aria-label="table-row"
        data-testid={rowId}
        id={rowId}
        {...generatedRowProps}
        className={classNames(generatedRowProps.className, classes.root)}
      >
        {row.cells.map((cell, cellIdx) => (
          <td
            aria-label="table-row-cell"
            key={cellIdx}
            {...cell.getCellProps()}
            className={classNames(classes.cell, {
              [classes.selectorColumn]: selectable && cellIdx === 0,
            })}
          >
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
      {row.isExpanded && rowSubComponent && rowSubComponent(row)}
    </>
  );
}

TableRow.propTypes = {
  rowId: PropTypes.string,
  row: PropTypes.shape({
    cells: PropTypes.arrayOf(PropTypes.object).isRequired,
    isExpanded: PropTypes.bool,
    values: PropTypes.object,
  }).isRequired,
  rowProps: PropTypes.func,
  prepareRow: PropTypes.func.isRequired,
  rowSubComponent: PropTypes.func,
  selectable: PropTypes.bool.isRequired,
};
