import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

export default function PendingDisbursementDetailActions({
  disbursement,
  canPrint,
  isEditing,
  handleSubmit,
  onToggleEdit,
  putOnHold,
  sendDisbursementBackToPending,
  removeHold,
  renderOnHoldDisbursements,
  showSendDisbursementBackToPending,
  canDelete,
  deletePendingDisbursement,
  clientId,
}) {
  const disbursementStatus = disbursement.attributes.status;
  const canBeUpdated = disbursement.meta.canBeUpdated;
  const canBeRevised = disbursement.meta.canBeRevised;

  const renderPutOnHoldAction = () => {
    if (renderOnHoldDisbursements) {
      if (
        canBeUpdated &&
        (disbursementStatus === 'Pending' ||
          disbursementStatus === 'Proposed' ||
          disbursementStatus === 'Additional Approval')
      ) {
        return (
          <button className="btn btn-link" onClick={putOnHold} type="button">
            Put On Hold
          </button>
        );
      }
    }
    return null;
  };

  const renderSendBackAction = () => {
    if (
      showSendDisbursementBackToPending &&
      disbursementStatus === 'Additional Approval' &&
      canBeRevised
    ) {
      return (
        <button className="btn btn-link" onClick={sendDisbursementBackToPending} type="button">
          Return for Revision
        </button>
      );
    }
    return null;
  };

  const renderRemoveHoldAction = () => {
    if (renderOnHoldDisbursements) {
      if (
        disbursementStatus === 'Pending on Hold' ||
        disbursementStatus === 'Proposed on Hold' ||
        disbursementStatus === 'Additional Approval on Hold'
      ) {
        return (
          <button className="btn btn-link" onClick={removeHold} type="button">
            Remove Hold
          </button>
        );
      }
    }
    return null;
  };

  const renderEditAction = () => {
    if (disbursementStatus !== 'Draft' && canBeUpdated) {
      return (
        <button
          className="btn btn-link"
          onClick={isEditing ? handleSubmit : onToggleEdit}
          type="button"
        >
          <TrueLinkIcon icon={'pen-to-square'} style={{ marginRight: '5px' }} />
          {isEditing ? 'Save' : 'Edit'}
        </button>
      );
    } else if (disbursementStatus === 'Approved') {
      return (
        <div>
          This disbursement has not yet been cleared. To make an adjustment, please email our
          support team: support@truelinkfinancial.com.
        </div>
      );
    }
    return null;
  };

  const renderPrintAction = () => {
    if (canPrint) {
      const printRoute = `${RailsRoutes.dashboard_organization_disbursement_path(
        clientId,
        disbursement.id,
      )}.pdf`;
      return (
        <a href={printRoute} rel="noreferrer" style={{ paddingRight: '15px' }} target="_blank">
          <TrueLinkIcon icon={'print'} style={{ marginRight: '5px' }} /> Print
        </a>
      );
    }
    return null;
  };

  const renderDeleteAction = () => {
    if (canDelete) {
      return (
        <a href="#" onClick={deletePendingDisbursement}>
          <TrueLinkIcon icon={'trash'} style={{ marginRight: '5px' }} /> Delete
        </a>
      );
    }
    return null;
  };

  return (
    <span className="disbursement-detail-actions">
      {renderSendBackAction()}
      {renderRemoveHoldAction()}
      {renderPutOnHoldAction()}
      {renderEditAction()}
      {renderPrintAction()}
      {renderDeleteAction()}
    </span>
  );
}

PendingDisbursementDetailActions.propTypes = {
  disbursement: PropTypes.any,
  canPrint: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onToggleEdit: PropTypes.func.isRequired,
  putOnHold: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sendDisbursementBackToPending: PropTypes.func,
  removeHold: PropTypes.func.isRequired,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  canDelete: PropTypes.bool,
  deletePendingDisbursement: PropTypes.func,
  clientId: PropTypes.string,
};
