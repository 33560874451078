import { useQuery } from '@tanstack/react-query';
import { fetchPendingDisbursementsIndex } from 'react/member/components/dashboard/disbursements/filter_table/utils/disbursements_v2_api';

export default function usePendingDisbursementIndexQuery({ clientId, filters, organizationSlug }) {
  const result = useQuery(['disbursements', clientId, filters, organizationSlug], () =>
    fetchPendingDisbursementsIndex(clientId, filters, organizationSlug),
  );

  return result;
}
