import axios from 'axios';
import Dropzone from 'dropzone';
import _compact from 'underscore/modules/compact';
import _find from 'underscore/modules/find';
import _map from 'underscore/modules/map';
import _pluck from 'underscore/modules/pluck';
import 'dropzone/dist/dropzone.css';
import DashboardActions from 'react/member/actions/dashboard_actions';

const AccountReceiptStore = flux.createStore({
  receiptsByFolder: [],
  folders: [],

  actions: [
    DashboardActions.fetchAccountReceiptsAndFolders,
    DashboardActions.updateAccountReceipt,
    DashboardActions.dropzoneAccountReceiptUploadSuccess,
    DashboardActions.V2destroyAttachment,
  ],

  fetchAccountReceiptsAndFolders(accountSlug) {
    const url = RailsRoutes.account_fetch_receipts_by_month_path(accountSlug, {
      format: 'json',
    });

    axios
      .get(url)
      .then((response) => {
        this.receiptsByFolder = _map(response.data, ({ month, receipts }) => ({
          folder: month,
          files: receipts,
        }));
        this.folders = _compact(_pluck(response.data, 'folder')) || [];
        this.emit('receiptsAndFolders.fetch');
      })
      .catch((_error) => this.emit('receipt.error'));
  },

  V2destroyAttachment(accountSlug, attachmentId) {
    const url = RailsRoutes.api_v2_attachment_path(attachmentId, { format: 'json' });

    axios
      .delete(url)
      .then((_response) => {
        this.emit('receipt.destroy');
        this.fetchAccountReceiptsAndFolders(accountSlug);
        Truelink.flash('success', 'Receipt successfully deleted!', false, true);
      })
      .catch((_error) => this.emit('receipt.error'));
  },

  updateAccountReceipt(accountSlug, receipt, newDate) {
    const url = RailsRoutes.api_v2_attachment_path(receipt.id, { format: 'json' });

    axios
      .put(url, {
        data: {
          type: 'attachment',
          attributes: {
            attachableId: receipt.attachable_id,
            attachableType: receipt.attachable_type,
            contentDate: newDate,
          },
        },
      })
      .then((_response) => {
        this.emit('receipt.update');
        this.fetchAccountReceiptsAndFolders(accountSlug);
        Truelink.flash('success', 'Receipt successfully updated!', false, true);
      })
      .catch((_error) => this.emit('receipt.error'));
  },

  dropzoneAccountReceiptUpload(ref) {
    this.emit('receipt.uploading');
    Dropzone.forElement(ref).processQueue();
  },

  dropzoneAccountReceiptUploadSuccess(accountSlug) {
    this.emit('receipt.upload');
    Truelink.flash('success', 'Receipt successfully uploaded!', false, true);
    this.fetchAccountReceiptsAndFolders(accountSlug);
  },

  exports: {
    getFilesByFolder() {
      return this.receiptsByFolder;
    },

    getFolders() {
      return this.folders;
    },

    getReceiptCountByMonth(monthlyFolderId) {
      const month = _find(this.receiptsByFolder, (obj) => obj.folder.id === monthlyFolderId);

      return month ? month.files.length : 0;
    },

    dropzoneSubmit(ref) {
      // must use export instead of DashboardAction when handing refs
      this.dropzoneAccountReceiptUpload(ref);
    },
  },
});

export default AccountReceiptStore;
