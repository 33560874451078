/* global user */
import AttachFile from '@mui/icons-material/AttachFile';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleRounded from '@mui/icons-material/RemoveCircleRounded';
import WarningOutlined from '@mui/icons-material/WarningOutlined';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TransactionRowDetails from './TransactionRowDetails';
import CardTransactionShape from 'react/member/card/shapes/CardTransactionShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';

export default function TransactionRow(props) {
  const [showDetail, setShowDetail] = useState(false);

  const toggleDetailsSlideDown = (e) => {
    e.preventDefault();
    setShowDetail((prevShowDetail) => !prevShowDetail);
  };

  const closeDetail = () => {
    setShowDetail(false);
  };

  const displayAmount = (amount, status, settled) => {
    if (status === 'blocked') {
      return (
        <span>
          <p>$0.00</p>
          <p className="nowrap">{asMoney(amount)} Blocked</p>
        </span>
      );
    }
    let amountVal = Number.parseFloat(amount).toFixed(2);
    if (!['-', '0'].includes(amountVal[0])) {
      amountVal = `+${amountVal}`;
    }

    if (settled) {
      return <p>{asMoney(amountVal)}</p>;
    }
    return (
      <span>
        <p>{asMoney(amountVal)}</p>
        <p>Pending</p>
      </span>
    );
  };

  const displayDate = (timestamp) => {
    try {
      const date = moment(timestamp);
      if (date.isBefore(moment().subtract(1, 'months'))) {
        return (
          <span>
            <p>{date.format('MMM D')}</p>
            <p>{date.fromNow()}</p>
          </span>
        );
      }
      return <p>{date.format('MMM D')}</p>;
    } catch (ex) {
      return <p>Unknown</p>;
    }
  };

  const displayLocation = (location, timestamp) => (
    <span>
      <p>{location}</p>
      {timestamp && <p>{moment(timestamp).format('h:mm a')}</p>}
    </span>
  );

  const displayMerchant = (name, category, is_admin, blockReason) => {
    let line2Part, blockReasonPart;
    const descriptionPart = <p>{name}</p>;
    if (category) {
      line2Part = <p>{category}</p>;
    }
    if (is_admin && blockReason) {
      blockReasonPart = <p>{blockReason}</p>;
    }
    return (
      <span>
        {descriptionPart}
        {line2Part}
        {blockReasonPart}
      </span>
    );
  };

  const displayStatus = (status) => {
    switch (status) {
      case 'blocked':
        return <RemoveCircleRounded className="blocked-icon" style={{ fill: PALETTE.red }} />;
      case 'flagged':
        return props.isCardholderView ? (
          <span />
        ) : (
          <WarningOutlined className="flagged-icon" style={{ fill: '#E7B12C' }} />
        );
      case 'allowed':
        return <CheckCircleRounded className="allowlisted-icon" style={{ fill: '#8CBF00' }} />;
      default:
        return <span />;
    }
  };

  const renderTransactionDetails = () => {
    if (showDetail) {
      return (
        <TransactionRowDetails
          cardCount={props.cardCount}
          closeDetail={closeDetail}
          detailKey={props.index}
          hideClosedCardTransactionsEnabled={props.hideClosedCardTransactionsEnabled}
          isCardholderView={props.isCardholderView}
          ruleSetId={props.ruleSetId}
          showNotes={props.showNotes}
          showQDE={props.showQDE}
          showReceipts={props.showReceipts}
          supportEmail={props.supportEmail}
          transaction={props.transaction}
        />
      );
    }
  };

  const { transaction, index, showReceipts } = props;
  const status = displayStatus(transaction.attributes.status);
  const buttonText = showDetail ? 'Close transaction' : 'Show transaction details';
  const rowClass = showDetail ? 'transaction_row row-highlight' : 'transaction_row';
  const arrowIcon = showDetail ? 'angle-up' : 'angle-down';
  const receiptFilteringEnabled = $tlf.flipperEnableTransactionReceiptFiltering;

  return (
    <tbody>
      <tr
        className={rowClass}
        data-transaction-id={index}
        key={transaction.id}
        onClick={toggleDetailsSlideDown}
      >
        <td className="vertical-align-middle">{status}</td>
        <td className="sorting_1 nowrap">{displayDate(transaction.attributes.timestamp)}</td>
        <td className="sorting_1">
          {displayMerchant(
            transaction.attributes.name,
            transaction.attributes.category,
            user.is_admin,
            transaction.attributes.block?.reason,
          )}
        </td>
        <td className="sorting_1">
          {displayLocation(transaction.attributes.location, transaction.attributes.timestamp)}
        </td>
        {receiptFilteringEnabled && showReceipts && (
          <td className="align-left nowrap">
            {transaction.relationships.attachments.data.length > 0 && (
              <p>
                <span>
                  <AttachFile
                    style={{ fill: PALETTE.black, fontSize: '18px', marginBottom: '-3px' }}
                  />
                </span>
                <span>Uploaded</span>
              </p>
            )}
          </td>
        )}
        <td className="align-right">
          {displayAmount(
            transaction.attributes.amount?.amount,
            transaction.attributes.status,
            transaction.attributes.settled,
          )}
        </td>
        <td className="align-right vertical-align-middle">
          <TrueLinkButton key={arrowIcon} variant="primary">
            {buttonText}
            <TrueLinkIcon icon={arrowIcon} style={{ marginLeft: '10px' }} />
          </TrueLinkButton>
        </td>
      </tr>
      {renderTransactionDetails()}
    </tbody>
  );
}

TransactionRow.propTypes = {
  cardCount: PropTypes.number.isRequired,
  hideClosedCardTransactionsEnabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isCardholderView: PropTypes.bool,
  showNotes: PropTypes.bool.isRequired,
  showQDE: PropTypes.bool.isRequired,
  showReceipts: PropTypes.bool.isRequired,
  supportEmail: PropTypes.string.isRequired,
  transaction: CardTransactionShape.isRequired,
  ruleSetId: PropTypes.string,
};
