import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import classNames from 'classnames';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import TaxDocRecipientDropdown from './TaxDocRecipientDropdown';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkMaskedTextInput from 'react/shared/components/true_link/lab/form/TrueLinkMaskedTextInput';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import addressShape from 'react/shared/shapes/address_shape';
import PALETTE from 'react/shared/theme/palette';
import ErrorText from 'react/signups/card/common/ErrorText';

const useStyles = makeStyles({
  taxIdModal: {
    '& .new-form__label': {
      width: 'auto',
      paddingRight: '0',
    },
    '& .new-form__data': {
      marginTop: '-10px',
    },
    '& .modal-content': {
      padding: '30px',
    },
    '& .modal-body': {
      border: 'none',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    '& .modal-header': {
      borderBottom: 'none',
      padding: '0px',
    },
    '& .MuiPaper-root': {
      width: '552px',
    },
  },
  submitBtn: {
    width: 'fit-content',
  },
  label: {
    color: '#000',
  },
  select: {
    height: 'auto',
    width: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '12px',
    '&.errorForm': {
      borderColor: PALETTE.red,
      outline: 'none',
    },
  },
  infoText: {
    color: PALETTE.grey3,
  },
  subheader: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '1px !important',
  },
  errorMessage: {
    padding: '18px 24px',
    borderRadius: '6px',
    border: `1px solid`,
    borderColor: PALETTE.red,
    background: PALETTE.redLight,
  },
});

export default function TaxInfoModal({
  ein,
  ssnOnFile,
  onSuccess,
  trustBeneficiary,
  taxIdNumberType,
}) {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [selectedTaxDocRecipient, setSelectedTaxDocRecipient] = useState(null);

  const validationSchema = Yup.object().shape({
    taxIdNumberType: Yup.string().nullable(),
    ein: Yup.string().when('taxIdNumberType', {
      is: 'ein',
      then: () => Yup.string().matches(/^\d{2}-?\d{7}$/, 'Incorrect format for EIN'),
    }),
  });

  const initialValues = {
    taxIdNumberType: taxIdNumberType || '',
    ein: ein || '',
  };

  const options = [
    {
      label: 'None selected',
      value: '',
    },
    {
      label: "Beneficiary's SSN",
      value: 'ssn',
    },
    {
      label: 'EIN',
      value: 'ein',
    },
  ];

  const updateTaxInfo = (values) => {
    const taxDocMailingAddressEnabled = $tlf.flipperTaxDocMailingAddress;
    const routeParams = { id: trustBeneficiary.id };

    let newConnectionId = null;
    if (taxDocMailingAddressEnabled) {
      newConnectionId = selectedTaxDocRecipient.id;

      if (selectedTaxDocRecipient.id === trustBeneficiary.id) {
        newConnectionId = null;
      }
    }

    let taxIdNumberTypeAttribute = values.taxIdNumberType;
    if (taxIdNumberTypeAttribute === '') {
      taxIdNumberTypeAttribute = null;
    }

    let beneAttributes = {
      taxIdNumberType: taxIdNumberTypeAttribute,
      ein: values.ein,
      taxDocRecipientConnectionId: newConnectionId,
    };

    if (values.taxIdNumberType === 'ssn' && !ssnOnFile) {
      beneAttributes = {
        taxDocRecipientConnectionId: newConnectionId,
      };
    }

    axios
      .patch(RailsRoutes.api_v2_trust_beneficiary_path(routeParams, { format: 'json' }), {
        data: {
          type: 'trust_beneficiary',
          id: trustBeneficiary.id.toString(),
          attributes: beneAttributes,
        },
      })
      .then(() => {
        Truelink.flash('success', 'Tax information successfully updated!');
        onSuccess();
        setIsModalOpen(false);
      })
      .catch(() => {
        Truelink.flash('error', 'There was an error trying to update the tax information');
        setIsModalOpen(false);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const openSsnModal = () => {
    setIsModalOpen(false);
    $('button#view-edit-ssn').trigger('click');
  };

  const handleContinue = (values) => {
    updateTaxInfo(values);
  };

  const taxDocMailingAddressEnabled = $tlf.flipperTaxDocMailingAddress;
  let mainLabel = 'Tax ID Number';
  let submitLabel = 'Update Tax ID Number';
  if (taxDocMailingAddressEnabled) {
    mainLabel = 'Tax Info';
    submitLabel = 'Submit';
  }

  return (
    <div className={classes.taxIdModal}>
      <div className="new-form-field">
        <div className="new-form__label">{mainLabel}:</div>
        <div className="new-form__data">
          <button className="btn-link" id="view-edit-ein" onClick={openModal} type="button">
            View/Edit
          </button>
        </div>
        {isModalOpen && (
          <TrueLinkModal
            handleClose={closeModal}
            iconCLoseBtn
            modalProps={{ className: classes.taxIdModal, maxWidth: 'md' }}
            title={<b>{mainLabel}</b>}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleContinue}
              validationSchema={validationSchema}
            >
              {({ handleChange, handleSubmit, setFieldTouched, values }) => (
                <>
                  {taxDocMailingAddressEnabled && (
                    <Typography className={classes.subheader}>ID Number</Typography>
                  )}
                  <Typography component="div" variant="body1">
                    Conveniently store Beneficiary or Trust Tax ID Number for annual tax purposes.
                    The Tax ID Number can be download in the Beneficiary Profile report.
                  </Typography>
                  <FormControl>
                    <select
                      className={classNames(
                        values.taxIdNumberType === 'ssn' && !ssnOnFile ? 'errorForm' : '',
                        classes.select,
                      )}
                      id="taxIdNumberType"
                      name="taxIdNumberType"
                      onChange={handleChange}
                      placeholder="Select an option"
                      value={values.taxIdNumberType}
                    >
                      {options.map((taxIdNumberType) => (
                        <option key={taxIdNumberType.label} value={taxIdNumberType.value}>
                          {taxIdNumberType.label}
                        </option>
                      ))}
                    </select>
                    <ErrorText name="taxIdNumberType" />
                  </FormControl>
                  {values.taxIdNumberType === 'ssn' && !ssnOnFile && (
                    <div className={classes.errorMessage}>
                      Please enter the beneficiary’s SSN before choosing this option. <br />
                      <TrueLinkLink onClick={openSsnModal}>
                        View / Edit Beneficiary SSN
                      </TrueLinkLink>
                    </div>
                  )}
                  {values.taxIdNumberType === 'ssn' && (
                    <Typography className={classes.infoText} component="div" variant="body1">
                      For security, only the last 4 digits of the SSN will be provided in the
                      Beneficiary Profile report.
                    </Typography>
                  )}
                  {values.taxIdNumberType === 'ein' && (
                    <>
                      <TrueLinkMaskedTextInput
                        labelText="9 digit Employee Identification Number"
                        mask="99-9999999"
                        name="ein"
                        onChange={handleChange}
                        placeholder="__-_______"
                        required
                        setFieldTouched={setFieldTouched}
                        value={values.ein}
                      />
                      <Typography component="div" variant="body1">
                        By submitting this form, you confirm and agree that the information provided
                        does not contain any personally identifiable information (PII).
                      </Typography>
                    </>
                  )}
                  {taxDocMailingAddressEnabled && (
                    <>
                      <Typography className={classes.subheader}>
                        Tax Document Mailing Address
                      </Typography>
                      <Typography component="div" variant="body1">
                        If you have an agreement for True Link to facilitate taxes for this
                        beneficiary, this is the address we'll send the K-1 or Grantor letter to.
                      </Typography>
                      <TaxDocRecipientDropdown
                        selectedTaxDocRecipient={selectedTaxDocRecipient}
                        setSelectedTaxDocRecipient={setSelectedTaxDocRecipient}
                        trustBeneficiary={trustBeneficiary}
                      />
                    </>
                  )}
                  <TrueLinkButton
                    className={classes.submitBtn}
                    disabled={values.taxIdNumberType === 'ssn' && !ssnOnFile}
                    onClick={() => {
                      handleSubmit();
                    }}
                    size="large"
                    type="submit"
                    variant="primary"
                  >
                    {submitLabel}
                  </TrueLinkButton>
                </>
              )}
            </Formik>
          </TrueLinkModal>
        )}
      </div>
    </div>
  );
}

TaxInfoModal.propTypes = {
  taxIdNumberType: PropTypes.string,
  ein: PropTypes.string,
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: addressShape,
    taxDocRecipientConnectionId: PropTypes.number,
  }).isRequired,
  ssnOnFile: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
};
