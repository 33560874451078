import Dropzone from 'dropzone';
import _compact from 'underscore/modules/compact';
import _isEmpty from 'underscore/modules/isEmpty';
import _map from 'underscore/modules/map';
import _pluck from 'underscore/modules/pluck';
import 'dropzone/dist/dropzone.css';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardDocumentStore = flux.createStore({
  documentsByFolder: [],
  folders: [],

  actions: [
    DashboardActions.fetchClientDocumentsAndFolders,
    DashboardActions.destroyClientDocument,
    DashboardActions.updateClientDocument,
    DashboardActions.dropzoneClientDocumentUploadSuccess,
    DashboardActions.createClientFolder,
    DashboardActions.updateClientFolder,
    DashboardActions.destroyClientFolder,
  ],

  destroyClientDocument(clientSlug, documentId, category = null) {
    $.ajax({
      url: RailsRoutes.api_v2_attachment_path(documentId, { format: 'json' }),
      type: 'DELETE',
      dataType: 'json',
    }).done(() => {
      this.emit('document.destroy');
      Truelink.flash('success', 'Document successfully deleted!');
      this.fetchClientDocumentsAndFolders(clientSlug, category);
    });
  },

  updateClientDocument(clientSlug, file, folderId) {
    const documentId = file.id;

    $.ajax({
      url: RailsRoutes.api_v2_attachment_path(documentId),
      type: 'PUT',
      data: JSON.stringify({
        data: {
          type: 'attachment',
          attributes: {
            attachableId: file.attachable_id,
            attachableType: file.attachable_type,
            folderId: _isEmpty(folderId) ? null : Number.parseInt(folderId, 10),
          },
        },
      }),
      dataType: 'json',
      contentType: 'application/json',
    }).done(() => {
      this.emit('document.update');
      Truelink.flash('success', 'Document successfully updated!');
      this.fetchClientDocumentsAndFolders(clientSlug);
    });
  },

  fetchClientDocumentsAndFolders(clientSlug, category) {
    const params = {
      format: 'json',
      // Ideally this would be tighter controlled, but it is legacy
      // eslint-disable-next-line no-eq-null
      category_filter: category == null ? 'none' : category,
    };
    $.get(
      RailsRoutes.dashboard_client_fetch_documents_and_folders_path(clientSlug, params),
      (documentsByFolder) => {
        this.documentsByFolder = _map(documentsByFolder, ({ folder, documents }) => ({
          folder,
          files: documents,
        }));
        this.folders = _compact(_pluck(documentsByFolder, 'folder')) || [];
        this.emit('documentsAndFolders.fetch');
      },
    );
  },

  createClientFolder(clientSlug, clientId, newFolderName) {
    const params = {
      folder: {
        trust_beneficiary_id: clientId,
        name: newFolderName,
      },
      format: 'json',
    };
    $.post(RailsRoutes.dashboard_folders_path(params))
      .done((_folder) => {
        this.emit('folder.create');
        Truelink.flash('success', 'Folder created!');
        this.fetchClientDocumentsAndFolders(clientSlug);
      })
      .fail(() => {
        Truelink.flash(
          'error',
          'Error creating folder! Please contact True Link support for further assistance.',
        );
      });
  },

  updateClientFolder(clientSlug, folder, newFolderName) {
    const params = {
      folder: {
        ...folder,
        name: newFolderName,
      },
    };
    $.ajax({
      url: RailsRoutes.dashboard_folder_path(folder.id),
      type: 'PUT',
      data: params,
      dataType: 'json',
    })
      .done(() => {
        this.emit('folder.update');
        Truelink.flash('success', 'Folder successfully updated!');
        this.fetchClientDocumentsAndFolders(clientSlug);
      })
      .fail(() => {
        Truelink.flash(
          'error',
          'Error updating folder! Please contact True Link support for further assistance.',
        );
        this.emit('folder.update');
      });
  },

  destroyClientFolder(clientSlug, folder, category) {
    $.ajax({
      url: RailsRoutes.dashboard_folder_path(folder.id),
      type: 'DELETE',
      dataType: 'json',
    })
      .done(() => {
        this.emit('folder.delete');
        Truelink.flash('success', 'Folder successfully deleted!');
        // Ideally this would be tighter controlled, but it is legacy
        // eslint-disable-next-line no-eq-null
        this.fetchClientDocumentsAndFolders(clientSlug, category == null ? 'none' : category);
      })
      .fail(() => {
        Truelink.flash(
          'error',
          'Error deleting folder! Please contact True Link support for further assistance.',
        );
        this.emit('folder.delete');
      });
  },

  dropzoneClientDocumentUpload(ref) {
    DashboardDocumentStore.emit('document.uploading');
    Dropzone.forElement(ref).processQueue();
  },

  dropzoneClientDocumentUploadSuccess(clientSlug, category) {
    DashboardDocumentStore.emit('document.upload');
    Truelink.flash('success', 'Document successfully uploaded!');

    this.fetchClientDocumentsAndFolders(clientSlug, category);
  },

  exports: {
    getFilesByFolder() {
      return this.documentsByFolder;
    },

    getFolders() {
      return this.folders;
    },

    dropzoneSubmit(ref) {
      // must use export instead of DashboardAction when handing refs
      this.dropzoneClientDocumentUpload(ref);
    },
  },
});

export default DashboardDocumentStore;
