import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { getConnectionsByTrustBeneficiary } from 'react/member/components/dashboard/trust_beneficiaries/profile/connections/api';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';

const EMPTY_STATE_ID = 'empty-state';

export default function TaxDocRecipientDropdown({
  trustBeneficiary,
  selectedTaxDocRecipient,
  setSelectedTaxDocRecipient,
}) {
  const [connections, setConnections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const taxDocRecipientId = trustBeneficiary.taxDocRecipientConnectionId;
  let beneAddress = '';
  if (trustBeneficiary.address) {
    beneAddress = `${trustBeneficiary.address.street1}${trustBeneficiary.address.street2 ? ` ${trustBeneficiary.address.street2}` : ''}, ${trustBeneficiary.address.city}, ${trustBeneficiary.address.state} ${trustBeneficiary.address.zip}`;
  }

  const trustBeneficiaryOption = useMemo(
    () => ({
      id: trustBeneficiary.id,
      label: {
        fullName: `${trustBeneficiary.firstName} ${trustBeneficiary.lastName}`,
        address: beneAddress,
      },
      category: 'Client',
    }),
    [trustBeneficiary, beneAddress],
  );

  useEffect(() => {
    getConnectionsByTrustBeneficiary(trustBeneficiary.id).then((connectionsResponse) => {
      setConnections(connectionsResponse.data);
      setIsLoading(false);
    });
  }, [trustBeneficiary.id]);

  const connectionOptions = useCallback(() => {
    if (connections.length === 0) {
      return [
        {
          id: EMPTY_STATE_ID,
          label: {
            fullName: 'No Connections with a valid mailing address',
            address: '',
          },
          category: 'Connections with valid mailing addresses',
          disabled: true,
        },
      ];
    }

    return connections.map((connection) => {
      const fullName = `${connection.person.firstName}${connection.person.lastName ? ` ${connection.person.lastName}` : ''}`;
      const formattedAddress = `${connection.address.street1}${connection.address.street2 ? ` ${connection.address.street2}` : ''}, ${connection.address.city}, ${connection.address.state} ${connection.address.zip}`;

      return {
        id: connection.id,
        label: {
          fullName,
          address: formattedAddress,
        },
        category: 'Connections with valid mailing addresses',
        address: connection.address,
      };
    });
  }, [connections]);

  useEffect(() => {
    if (taxDocRecipientId) {
      setSelectedTaxDocRecipient(
        connectionOptions().find((recipient) => recipient.id === taxDocRecipientId),
      );
    } else {
      setSelectedTaxDocRecipient(trustBeneficiaryOption);
    }
  }, [taxDocRecipientId, trustBeneficiaryOption, connectionOptions, setSelectedTaxDocRecipient]);

  const filterOptions = (options, state) => {
    const inputValue = state.inputValue.toLowerCase().trim();

    return options.filter((option) => {
      if (option.id === EMPTY_STATE_ID) return true;

      const isValidAddress =
        option.address?.street1 &&
        option.address?.city &&
        option.address?.state &&
        option.address?.zip;

      if (!isValidAddress && option !== trustBeneficiaryOption) return false;

      const fullName = option.label.fullName.toLowerCase();
      const address = option.label.address.toLowerCase();

      return fullName.includes(inputValue) || address.includes(inputValue);
    });
  };

  if (isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      <Autocomplete
        autoComplete
        autoHighlight
        autoSelect
        disableClearable
        filterOptions={filterOptions}
        freeSolo={false}
        fullWidth
        getOptionDisabled={(option) => option.id === EMPTY_STATE_ID}
        getOptionLabel={(option) => `${option.label?.fullName} - ${option.label?.address}`}
        groupBy={(option) => option.category}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          setSelectedTaxDocRecipient(newValue);
        }}
        options={[trustBeneficiaryOption, ...connectionOptions()]}
        renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
        renderOption={(props, option) => (
          <li {...props}>
            <div>
              {option.label?.fullName}
              <Typography
                component="p"
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                }}
              >
                {option.label?.address}
              </Typography>
            </div>
          </li>
        )}
        value={selectedTaxDocRecipient ?? trustBeneficiaryOption}
      />
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        Need to add a new address?
        <InfoTooltip
          placement="right"
          tooltipText="To add a new mailing address, first create a new Connection with a valid address for this beneficiary. Then, select the new address from the dropdown."
        />
      </Box>
    </>
  );
}

TaxDocRecipientDropdown.propTypes = {
  trustBeneficiary: PropTypes.object.isRequired,
  selectedTaxDocRecipient: PropTypes.object,
  setSelectedTaxDocRecipient: PropTypes.func.isRequired,
};
