import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import DashboardPayee from './DashboardPayee';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import Datatable from 'react/shared/components/Datatable';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPaginatedPayeeList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPayeeFormOpen: false,
      editPayeeFormOpen: false,
      currentPayee: null,
      currentPaymentMethod: null,
      displayedPaymentMethods: this.displayedPaymentMethodsIfAvailable(),
      needsRefresh: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardPayeeStore.on('payees.add', this.refresh);
    DashboardPayeeStore.on('payees.update', this.refresh);
    DashboardPayeeStore.on('payee.destroy', this.refresh);
  }

  componentWillUnmount() {
    DashboardPayeeStore.off('payees.add', this.refresh);
    DashboardPayeeStore.off('payees.update', this.refresh);
    DashboardPayeeStore.off('payee.destroy', this.refresh);
  }

  afterRefresh() {
    this.setState({
      needsRefresh: false,
      newPayeeFormOpen: false,
      editPayeeFormOpen: false,
    });
  }

  refresh() {
    this.setState({ needsRefresh: true });
  }

  toggleNewPayeeForm() {
    this.setState({
      newPayeeFormOpen: !this.state.newPayeeFormOpen,
      currentPayee: null,
      currentPaymentMethod: null,
    });
  }

  async toggleEditPayeeForm(payeeData) {
    const payee = await axios.get(`${RailsRoutes.api_v2_payee_path(payeeData.id)}?include=address`);
    if (payee?.data?.included?.[0].attributes) {
      payeeData.attributes.address_attributes = payee.data.included[0].attributes;
    }
    this.setState({
      editPayeeFormOpen: !this.state.editPayeeFormOpen,
      currentPayee: payeeData,
      currentPaymentMethod: payeeData.attributes.supportedPaymentMethods[0],
    });
  }

  closeNewPayeeForm() {
    this.setState({
      newPayeeFormOpen: false,
      currentPayee: null,
    });
  }

  closeEditPayeeForm() {
    this.setState({
      editPayeeFormOpen: false,
      currentPayee: null,
    });
  }

  displayedPaymentMethodsIfAvailable() {
    if (this.props.portfolio) {
      return this.props.portfolio.trust.displayed_payment_types;
    }
    if (this.props.client) {
      return this.props.client.trust.displayed_payment_types;
    }
  }

  payeesRoute() {
    const id = this.props.client.slug;
    const route = RailsRoutes.api_v2_trust_beneficiary_payees_path;

    return route(id, { format: 'json' });
  }

  pageLength() {
    return 25;
  }

  initialSort() {
    return [[0, 'asc']];
  }

  columns() {
    return [
      <th className="js-datatable-col-payee" key="datatable-payee">
        Payee
      </th>,
    ]
      .concat(this.clientNameHeader())
      .concat([
        <th
          className="js-datatable-col-datatable-supported-payment-types"
          key="datatable-supported-payment-types"
        >
          Supported Payment Types
        </th>,
        <th className="js-datatable-col-datatable-account-number" key="datatable-account-number">
          Memo
        </th>,
        <th className="js-datatable-col-action" key="datatable-action">
          Action
        </th>,
      ]);
  }

  clientNameHeader() {
    if (this.props.showClientName) {
      return [
        <th className="js-datatable-col-client" key="datatable-client">
          Client Name
        </th>,
      ];
    }
    return [];
  }

  columnDefs() {
    return [
      {
        targets: ['js-datatable-col-payee'],
        data: 'attributes.name',
      },
      {
        targets: ['js-datatable-col-client'],
        data: 'client.name',
      },
      {
        targets: ['js-datatable-col-datatable-supported-payment-types'],
        orderable: false,
        data: 'attributes.supportedPaymentMethods',
        render: this.formatPaymentMethods,
      },
      {
        targets: ['js-datatable-col-datatable-account-number'],
        data: 'attributes.clientAccountNumber',
      },
      {
        targets: ['js-datatable-col-action'],
        orderable: false,
        data: null,
        render: (_data, _type, _record) => '<a class="js-payee-view-action" href="#">View</a>',
      },
    ];
  }

  formatPaymentMethods(data, _type, _record) {
    return _map(data, (method) => $tlf.domains.paymentMethodsMap[method]);
  }

  afterDraw(settings) {
    const api = new $.fn.DataTable.Api(settings);
    if (!api.data().length) {
      return;
    }
    return api
      .rows()
      .nodes()
      .each((row, i) => {
        const $row = $(row);
        return $row.find('.js-payee-view-action').data({ payee: api.data()[i] });
      });
  }

  datatableEventListeners() {
    // This is using an old style jquery click event to handle this form.
    // It should be done using React if this needs to be modified.
    // eslint-disable-next-line consistent-this
    const component = this;
    return $('.js-payee-view-action').click(function (ev) {
      ev.preventDefault();
      // This is the reality of how jQuery wants us to query objects. We should avoid jQuery.
      // eslint-disable-next-line no-invalid-this
      return component.toggleEditPayeeForm($(this).data('payee'));
    });
  }

  handleRender(table) {
    return <div>{table}</div>;
  }

  renderNewDashboardPayee() {
    if (!this.props.client || !this.props.client.slug || this.props.userRoles.viewOnly) {
      return null;
    }

    return (
      <div>
        <div className="pull-right" style={{ marginBottom: 20 }}>
          <button className="btn normal" onClick={this.toggleNewPayeeForm} type="button">
            <b>+</b> Create new payee
          </button>
        </div>

        {this.state.newPayeeFormOpen && (
          <DashboardPayee
            clientSlug={this.props.client.slug}
            displayedPaymentMethods={this.state.displayedPaymentMethods}
            formType="new"
            onClose={this.closeNewPayeeForm}
            userRoles={this.props.userRoles}
          />
        )}
      </div>
    );
  }

  renderEditDashboardPayee() {
    if (!this.state.editPayeeFormOpen) {
      return null;
    }
    let associationProp, client;
    if ((client = this.props.client)) {
      associationProp = { clientSlug: client.slug };
    }

    return (
      <DashboardPayee
        displayedPaymentMethods={this.state.displayedPaymentMethods}
        formType="edit"
        initialPayee={this.state.currentPayee}
        onClose={this.closeEditPayeeForm}
        paymentMethod={this.state.currentPaymentMethod}
        startOnConfirmationPage
        userRoles={this.props.userRoles}
        {...associationProp}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderNewDashboardPayee()}
        {this.renderEditDashboardPayee()}
        <Datatable
          addEventListeners={this.datatableEventListeners}
          afterDraw={this.afterDraw}
          afterRefresh={this.afterRefresh}
          columnDefs={this.columnDefs()}
          columns={this.columns()}
          handleRender={this.handleRender}
          hidePagination
          initialSort={this.initialSort()}
          needsRefresh={this.state.needsRefresh}
          pageLength={this.pageLength()}
          route={this.payeesRoute()}
          rowIdPrefix="payee"
          shouldShowSearchBox={false}
        />
      </div>
    );
  }
}

DashboardPaginatedPayeeList.propTypes = {
  client: PropTypes.object,
  portfolio: PropTypes.object,
  showClientName: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
};
