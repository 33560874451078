import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TransferFundsModal from 'react/member/card/components/funding_tab/transfers/TransferFundsModal';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function TransferEditCell({
  cardStatus,
  isEffectivelyFullyFunded,
  isViewOnly,
  emergencyFundingEnabled,
  fundingType,
  transfer,
}) {
  const [showUpsertModal, setUpsertModal] = useState(false);

  const handleOpenUpsertModal = () => setUpsertModal(true);
  const handleCloseUpsertModal = () => setUpsertModal(false);

  if (isViewOnly || !transfer.editable) return null;

  return (
    <>
      <TrueLinkButton
        className="btn-link"
        data-transfer-id={transfer.id}
        onClick={handleOpenUpsertModal}
        variant="none"
      >
        Edit
      </TrueLinkButton>
      {showUpsertModal && (
        <TransferFundsModal
          bankAccount={transfer.bankAccount}
          cardStatus={cardStatus}
          cardholderName={transfer.cardholder?.name}
          emergencyFundingEnabled={emergencyFundingEnabled}
          fundingType={fundingType}
          isEffectivelyFullyFunded={isEffectivelyFullyFunded}
          onClose={handleCloseUpsertModal}
          onSuccess={handleCloseUpsertModal}
          transfer={transfer}
        />
      )}
    </>
  );
}

TransferEditCell.propTypes = {
  isEffectivelyFullyFunded: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  emergencyFundingEnabled: PropTypes.bool,
  fundingType: PropTypes.string,
  cardStatus: PropTypes.string,
  transfer: transferShape.isRequired,
};
