import axios from 'axios';
import Actions from 'react/shared/actions/actions';
import ListItemStore from 'react/shared/utils/list_item_store';

const ConnectionStore = flux.createStore({
  connections: new ListItemStore(),
  commonConnections: [],
  relationshipTypes: null,

  actions: [
    Actions.fetchConnections,
    Actions.fetchRelationshipTypes,
    Actions.createConnection,
    Actions.updateConnection,
    Actions.updateInvitedConnection,
    Actions.connectConnection,
    Actions.removeConnection,
  ],

  fetchConnections(trustBeneficiarySlug, searchCommon = false) {
    const params = searchCommon ? { searchCommon: true } : {};
    axios
      .get(RailsRoutes.dashboard_client_connections_path(trustBeneficiarySlug, params))
      .then((connectionsResponse) => {
        // fetch vs. fetchSearch need to be separate events. Otherwise, the search results will overwrite the main list.
        if (searchCommon) {
          this.commonConnections = connectionsResponse.data;
          this.emit('connections.fetchSearch', this.commonConnections);
          return;
        }
        const updatedItems = this.connections.setItems(
          trustBeneficiarySlug,
          connectionsResponse.data,
        );
        this.emit(`connections.fetch.${trustBeneficiarySlug}`, updatedItems);
        this.emit(`connections.updated.${trustBeneficiarySlug}`, updatedItems);
      });
  },

  fetchRelationshipTypes(trustBeneficiarySlug) {
    if (this.relationshipTypes !== null) {
      this.emit('connections.fetchRelationshipTypes', this.relationshipTypes);
      return;
    }

    axios
      .get(RailsRoutes.dashboard_client_connection_relationships_path(trustBeneficiarySlug))
      .then((connectionRelationshipsResponse) => {
        this.relationshipTypes = connectionRelationshipsResponse.data;
        this.emit('connections.fetchRelationshipTypes', this.relationshipTypes);
      });
  },

  createConnection(trustBeneficiarySlug, connection, onRequestCompleted) {
    const params = { connection };
    axios
      .post(RailsRoutes.dashboard_client_connections_path(trustBeneficiarySlug), params)
      .then((createConnectionResponse) => {
        const updatedItems = this.connections.addItems(trustBeneficiarySlug, [
          createConnectionResponse.data,
        ]);
        this.emit(`connections.create.${trustBeneficiarySlug}`, createConnectionResponse.data);
        this.emit(`connections.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch(() => {
        this.emit(`connections.create.${trustBeneficiarySlug}.error`, connection);
      })
      .finally(onRequestCompleted);
  },

  updateConnection(trustBeneficiarySlug, connection, onRequestCompleted) {
    const params = { connection };
    axios
      .patch(
        RailsRoutes.dashboard_client_connection_path(trustBeneficiarySlug, connection.id),
        params,
      )
      .then((updateConnectionResponse) => {
        const updatedItems = this.connections.updateItem(
          trustBeneficiarySlug,
          updateConnectionResponse.data,
        );
        this.emit(`connections.update.${trustBeneficiarySlug}`, connection);
        this.emit(`connections.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch(() => {
        this.emit(`connections.update.${trustBeneficiarySlug}.error`, connection);
      })
      .finally(onRequestCompleted);
  },

  // This is a bit different in that we're accepting a connection we already have and associating it
  // with a new beneficiary portal invitation. This makes it so the user access invitation controller
  // on the server side does to not have to render the invitee along with the invitation.
  updateInvitedConnection(trustBeneficiarySlug, connection, invitation) {
    connection.userAccessInvitation = invitation;

    const updatedItems = this.connections.updateItem(trustBeneficiarySlug, connection);
    this.emit(`connections.update.${trustBeneficiarySlug}`, connection);
    this.emit(`connections.updated.${trustBeneficiarySlug}`, updatedItems);
  },

  connectConnection(trustBeneficiarySlug, connectionInfo, onRequestCompleted) {
    axios
      .post(
        RailsRoutes.dashboard_client_connection_relationships_path(trustBeneficiarySlug),
        connectionInfo,
      )
      .then((linkConnectionResponse) => {
        const updatedItems = this.connections.addItems(trustBeneficiarySlug, [
          linkConnectionResponse.data,
        ]);
        this.emit(`connections.link.${trustBeneficiarySlug}`, linkConnectionResponse.data);
        this.emit(`connections.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch(() => {
        this.emit(`connections.link.${trustBeneficiarySlug}.error`, connectionInfo);
      })
      .finally(onRequestCompleted);
  },

  removeConnection(trustBeneficiarySlug, connection, onRequestCompleted) {
    axios
      .delete(
        RailsRoutes.dashboard_client_connection_relationship_path(
          trustBeneficiarySlug,
          connection.connectionRelationship.id,
        ),
      )
      .then(() => {
        const updatedItems = this.connections.removeItem(trustBeneficiarySlug, connection);
        this.emit(`connections.unlink.${trustBeneficiarySlug}`, connection);
        this.emit(`connections.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch(() => {
        this.emit(`connections.unlink.${trustBeneficiarySlug}.error`, connection);
      })
      .finally(onRequestCompleted);
  },
});

export default ConnectionStore;
