import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import connectionShape from 'react/member/shapes/ConnectionShape';
import Actions from 'react/shared/actions/actions';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function DashboardTrustBeneficiaryProfileConnectionsRemoveForm({
  connection,
  trustBeneficiary,
}) {
  const [isSubmitting, updateSubmitting] = useState(false);

  const onSubmit = (ev) => {
    ev.preventDefault();
    updateSubmitting(true);
    const afterRemove = () => {
      // Refetch the bene to display the updated tax doc recipient, just in case it was deleted here
      DashboardActions.fetchTrustBeneficiaryData(trustBeneficiary.slug);
      updateSubmitting(false);
    };
    Actions.removeConnection(trustBeneficiary.slug, connection, afterRemove);
  };

  const connectionName = connection.companyName
    ? connection.companyName
    : `${connection.person.firstName} ${connection.person.lastName}`;

  return (
    <div>
      <div>
        Are you sure you wish to remove {connectionName} as a connection? Their information will
        still be available, but they will no longer be connected to this beneficiary.
      </div>
      <div>
        <form onSubmit={onSubmit}>
          <div className="tl-form-actions-row">
            <TrueLinkButton
              className={classNames('btn btn-danger', {
                disabled: isSubmitting,
              })}
              disabled={isSubmitting}
              type="submit"
              variant="none"
            >
              Remove Connection
            </TrueLinkButton>
          </div>
        </form>
      </div>
    </div>
  );
}

DashboardTrustBeneficiaryProfileConnectionsRemoveForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  connection: connectionShape.isRequired,
};
