import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AutoTransferDeleteModal from 'react/member/card/components/funding_tab/auto_transfers/AutoTransferDeleteModal';
import AutoTransferReviewChangesModal from 'react/member/card/components/funding_tab/auto_transfers/AutoTransferReviewChangesModal';
import AutoTransferUpsertModal from 'react/member/card/components/funding_tab/auto_transfers/AutoTransferUpsertModal';
import TransferFundsModal from 'react/member/card/components/funding_tab/transfers/TransferFundsModal';
import SingleTransferHolidayContainer from 'react/member/card/components/holidays/SingleTransferHolidayContainer';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
export default function AutoTransferEditCell({
  autoTransfer,
  cardholderName,
  cardStatus,
  emergencyFundingEnabled,
  fundingType,
  isViewOnly,
}) {
  // Hooks
  // -----------------------------------------------------
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showTransferFundsModal, setShowTransferFundsModal] = useState(false);
  const [showUpsertModal, setUpsertModal] = useState(false);
  const [showReviewChangesModal, setReviewChangesModal] = useState(false);
  const [showHolidayImpactedTransferModal, setHolidayImpactedTransferModal] = useState(false);
  const [holidayImpactedTransfer, setHolidayImpactedTransfer] = useState();

  // Guards
  // -----------------------------------------------------
  if (isViewOnly || !autoTransfer.editable) return null;

  // Handlers
  // -----------------------------------------------------
  // AutoTransferDeleteModal
  const handleOpenDeleteModal = () => setDeleteModal(true);
  const handleCloseDeleteModal = () => setDeleteModal(false);
  const handleDeleteAutoTransfer = () => {
    DashboardActions.deleteAutoTransfer(autoTransfer.id, handleCloseDeleteModal);
  };

  // AutoTransferReviewChangesModal
  const handleOpenReviewChangesModal = () => setReviewChangesModal(true);
  const handleCloseReviewChangesModal = () => setReviewChangesModal(false);

  // AutoTransferUpsertModal -> submit handler located inside modal
  const handleCloseUpsertModal = () => setUpsertModal(false);

  const handleOpenTransferFundsModal = () => setShowTransferFundsModal(true);
  const handleCloseTransferFundsModal = () => setShowTransferFundsModal(false);

  const handleEditTransfer = () => {
    handleOpenTransferFundsModal();
  };

  const handleUpdateSeriesSuccess = (data) => {
    handleCloseUpsertModal();
    handleCloseTransferFundsModal();
    DashboardActions.fetchScheduledTransfers();
    if (data.updatedRecurrenceInformation) {
      if (data.upcomingHolidayImpactedTransfer) {
        setHolidayImpactedTransfer(data.upcomingHolidayImpactedTransfer);
        setHolidayImpactedTransferModal(true);
      } else {
        handleOpenReviewChangesModal();
        DashboardActions.reloadHolidayBanner();
      }
    }
  };

  // SingleTransferHolidayContainer
  const handleCloseHolidayImpactedTransferModal = () => {
    setHolidayImpactedTransferModal(false);
    // Reload transfers again to pick up holiday guidance changes
    DashboardActions.fetchScheduledTransfers();
    DashboardActions.reloadHolidayBanner();
  };

  return (
    <>
      <TrueLinkButton
        className="btn-link"
        data-auto-transfer-id={autoTransfer.id}
        onClick={handleEditTransfer}
        variant="none"
      >
        Edit
      </TrueLinkButton>
      {showUpsertModal && (
        <AutoTransferUpsertModal
          autoTransfer={autoTransfer}
          bankAccount={autoTransfer.bankAccount}
          edit
          onClose={handleCloseUpsertModal}
          onDelete={handleOpenDeleteModal}
          onUpdateSuccess={handleUpdateSeriesSuccess}
        />
      )}

      {showTransferFundsModal && (
        <TransferFundsModal
          bankAccount={autoTransfer.bankAccount}
          cardStatus={cardStatus}
          cardholderName={cardholderName}
          emergencyFundingEnabled={emergencyFundingEnabled}
          fundingType={fundingType}
          onClose={handleCloseTransferFundsModal}
          onDelete={handleOpenDeleteModal}
          onSuccess={handleUpdateSeriesSuccess}
          transfer={autoTransfer}
        />
      )}
      {showReviewChangesModal && (
        <AutoTransferReviewChangesModal
          cardholderName={cardholderName}
          onClose={handleCloseReviewChangesModal}
        />
      )}
      {showHolidayImpactedTransferModal && (
        <SingleTransferHolidayContainer
          holidayImpactedTransfer={holidayImpactedTransfer}
          onClose={handleCloseHolidayImpactedTransferModal}
        />
      )}
      {showDeleteModal && (
        <AutoTransferDeleteModal
          autoTransfer={autoTransfer}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteAutoTransfer}
        />
      )}
    </>
  );
}

AutoTransferEditCell.propTypes = {
  autoTransfer: autoTransferShape.isRequired,
  cardholderName: PropTypes.string,
  cardStatus: PropTypes.string,
  emergencyFundingEnabled: PropTypes.bool,
  fundingType: PropTypes.string,
  isViewOnly: PropTypes.bool.isRequired,
};
